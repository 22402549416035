import React, { Component } from 'react';
import FormControl from '@material-ui/core/FormControl';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import axios from 'axios';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close'; 

const styles = theme => ({
    root: {
        width: '100%'
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    form: {
        width: '100%',
        margin: '0px',
        display: 'inline-flex',
        '& > *': {
            margin: theme.spacing(1),
            width: '100%',
        },
    },
    buttonGrid: {
        alignItems: 'center',
        display: 'flex'
    },
    inputfield: {
        width: '100px'
    },
    table: {
        minWidth: 650,
    },
    textField:{
    width: '400px'
    },
    alertDate: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textFieldAlert: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 250
  },
});

class Alertpanel extends Component {

    constructor(props) {

        super(props);
        this.state = {
            learnMoredialog: false,
            data:false,
            error:false,
            start_time: new Date().getTime(),
            end_time:new Date().getTime(),
            description:"",
            title:'',
            categories: ["sap","akamai","windchill","ccrm"],
            category:"sap",
            alertData:{
                description: "	This is to inform you about a planned maintenance activity for One BI, CL BW & BPC Applications (WPC system). <b>During this period, Password Reset/Account Unlock will not be available for users for WPC, WDC, WQC, WPP. </b><br> The schedule maintenance window for WPP is : Sunday, 21st March 2021 ( 00:30 AM CET – 06:30 AM CET) <br> The schedule maintenance window for WPC, WDC, WQC is : Sunday, 21st March 2021 ( 10:30 AM CET – 16:30 AM CET)",
                end_time: 1619176829130,
                start_time: 1619176829130,
                title: "WPC, WQC, WDC, WPP Down"
                
            },
            alertsList:[],
            endDateFeild:new Date().toISOString().substr(0,16),
            startDateFeild:new Date().toISOString().substr(0,16),
            snackbarMessage:"",
            snackbarVisibility:false


        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }





    componentDidMount() {
      this.getData()
    }



    getData() {

        axios.get('https://www.inside.athena.philips.com/login/announcement/getAnnouncements').then(response => {
            
            if (response.status === 200) {
                let filterTime = (new Date().getTime()) - 2629746000;
                let filtered_result = [];
                response.data.map(e => {
                    if (e.end_time >= filterTime) {
                        filtered_result.push(e);
                    }
                });
                //.log(response.data)
            this.setState({
                    alertsList: filtered_result.reverse(),
                // startDateFeild : new Date(response.data.result.start_time).toISOString().substr(0,16),
                // endDateFeild : new Date(response.data.result.end_time).toISOString().substr(0,16),
                data:true

            });
            }
            else{
              this.setState({
                data:false,
                error:true,
                snackbarMessage:"The was an error fetching the alerts, Please try again later.",
                snackbarVisibility:true 
              })
            }

        }).catch(error => {
            console.log(error)
            this.setState({
                data:false,
                error:true,
                snackbarMessage:"The was an error fetching the alerts, Please try again later.",
                snackbarVisibility:true
                
              })
        })

    }

   editAlert = () => {
       this.setState({
                title: this.state.alertData.title,
                description: this.state.alertData.description,
                startDateFeild: new Date(this.state.alertData.start_time).toISOString().substr(0,16),
                endDateFeild: new Date(this.state.alertData.end_time).toISOString().substr(0,16),
                start_time: this.state.alertData.start_time,
                end_time: this.state.alertData.end_time,

            });

   }

   handleSystemChange = (event) => {
        this.setState({
            category: event.target.value
        })
    }

    handleDelete = async (alert) => {
        this.setState({
            data:false
         })
        var alert_id = alert
        try{
        const deleteAlert = await axios.get(`https://www.inside.athena.philips.com/login/announcement/deleteAnnouncements?id=${alert_id}`) 
    // console.log(deleteAlert)
        if(deleteAlert.data.status==='pass'){
             
             this.setState({
                snackbarMessage:"Alert deleted successfully",
                snackbarVisibility:true,
                 notificationColor:"#4caf50"
             }, ()=> this.getData())
             this.getData()
        }else{
            this.setState({
                snackbarMessage:"Something went wrong, please try again",
                snackbarVisibility:true
            })
            this.getData()
        }
        }catch(error){
            console.error(error)
            this.setState({
                snackbarMessage:"Something went wrong, please try again",
                snackbarVisibility:true
            })
        }
    }

    copyAlert = async (alert) => {
        window.scrollTo({top: 0, behavior: 'smooth'})
        //console.log(alert)
        this.setState({
            title: alert.title,
            description: alert.description,
            startDateFeild: new Date(alert.start_time).toISOString().substr(0,16),
            endDateFeild: new Date(alert.end_time).toISOString().substr(0,16),
            start_time: alert.start_time,
            end_time: alert.end_time,
            category: alert.application.toLowerCase()

        });
    }
   
    handleSubmit(event) {
        

          if(this.state.start_time <= 0 || this.state.end <=0 || this.state.description.length <=0 || this.state.title.length <=0 || this.state.category.length <= 0){
 
         alert('Please verify the details again')
          }

          else{
             // console.log(this.state)
        axios.post('https://www.inside.athena.philips.com/login/announcement/addAnnouncements',
            {
                start_time: this.state.start_time,
                end_time: this.state.end_time,
                description: this.state.description,
                title:this.state.title,
                application:this.state.category.toLowerCase()
            },
            {
                headers: {
                    'Content-Type': 'application/json'
                }

            }).then(resp => {
                if(resp.data.status==='pass'){
                
                    this.setState({
                        description: '',
                        title:"",
                        data:false,
                        startDateFeild:null,
                        endDateFeild:null,
                        application:"",
                        snackbarMessage:"Alert added successfully",
                        snackbarVisibility:true
                     }, ()=> this.getData())
                    // this.getData()
                     }
                     else{
                        if(resp.data.status==='fail'){
                            alert("Action failed. Please try again or check the details.")
                        }
                        else{
                           alert('Please try again')
                        }
                         this.setState({
                        description: '',
                        title:'',
                        data:false,
                        snackbarMessage:"Something went wrong, please try again",
                        snackbarVisibility:true
                        
                     }, ()=> this.getData())
                         
                     }
                
            }).catch(error => {
                this.setState({
                   description: '',
                   title:'',
                   data:false
                })
                console.log(error);
            });
          }

       
        event.preventDefault();

    }

    handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        this.setState({
            snackbarVisibility:false,
            snackbarMessage:""
        })
      };


      render() {

        const { classes } = this.props;

        const action = (
            <React.Fragment>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={this.handleSnackbarClose}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          );

        return (
                
            <div className={classes.root}>

                    <Snackbar
                            open={this.state.snackbarVisibility}
                            anchorOrigin={{ vertical:'top', horizontal:'right' }}
                            autoHideDuration={3000}
                            message={this.state.snackbarMessage}
                            onClose={this.handleSnackbarClose}
                            action={action}
                            
                        />
                            
                            
               
                  
                <ExpansionPanel defaultExpanded={true}>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.heading}><b>Alerts</b></Typography>
                       
                    </ExpansionPanelSummary>
                  <ExpansionPanelDetails >
                   <Grid container spacing={3} alignItems="center" >
                  
                                <Grid item xs={6} sm={3}  justify="center" alignItems="center">
                                  <TextField
                                        required
                                        id="datetime-local"
                                        label="Start Time in GMT"
                                        type="datetime-local"
                                        value = {this.state.startDateFeild}
                                        className={classes.textFieldAlert}
                                        style = {{width:"100%"}}
                                        InputLabelProps={{
                                        shrink: true,
                                        }}
                                        onChange = { (event) => {
                                           // console.log(event.target.value)
                                            let startDate = new Date(event.target.value);
                                            let start_time = 0;
                                           // console.log("the modified date",new Date(startDate.getTime()-(startDate.getTimezoneOffset()*60*1000)))
                                            if(Math.sign(startDate.getTimezoneOffset())>=0){
                                                  //console.log(startDate,startDate.getTime(),startDate.getTimezoneOffset()*60*1000,startDate.getTime()+(startDate.getTimezoneOffset()*60*1000));
                                                  start_time = startDate.getTime()+(startDate.getTimezoneOffset()*60*1000);
                                            }else{
                                                 //console.log(startDate,startDate.getTime(),startDate.getTimezoneOffset()*60*1000,startDate.getTime()-(startDate.getTimezoneOffset()*60*1000));
                                                 start_time = startDate.getTime()-(startDate.getTimezoneOffset()*60*1000)
                                            }

                                            this.setState({
                                                start_time : start_time,
                                                startDateFeild:event.target.value
                                            })
                                            //console.log(this.state.start_time,this.state.startDateFeild)
                                            
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={3}  justify="center" alignItems="center">
                                 <TextField
                                    required
                                        id="datetime-local"
                                        label="End Time in GMT"
                                        type="datetime-local"
                                        value = {this.state.endDateFeild}
                                        className={classes.textFieldAlert}
                                        style = {{width:"100%"}}
                                        InputLabelProps={{
                                        shrink: true,
                                        }}
                                        onChange = { (event) => {
                                            //console.log(this.state)
                                          //console.log(event.target.value)
                                            let startDate = new Date(event.target.value);
                                            let end_time = 0;
                                            //console.log("the modified date",new Date(startDate.getTime()-(startDate.getTimezoneOffset()*60*1000)))
                                            if(Math.sign(startDate.getTimezoneOffset())>=0){
                                                 // console.log(startDate,startDate.getTime(),startDate.getTimezoneOffset()*60*1000,startDate.getTime()+(startDate.getTimezoneOffset()*60*1000));
                                                  end_time = startDate.getTime()+(startDate.getTimezoneOffset()*60*1000);
                                            }else{
                                                // console.log(startDate,startDate.getTime(),startDate.getTimezoneOffset()*60*1000,startDate.getTime()-(startDate.getTimezoneOffset()*60*1000));
                                                 end_time = startDate.getTime()-(startDate.getTimezoneOffset()*60*1000)
                                            }

                                            this.setState({
                                                end_time : end_time,
                                                endDateFeild : event.target.value
                                            })
                                            
                                            
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={2} sm={2} >
                                <FormControl style = {{width:"100%"}} >
                                        <InputLabel id="demo-simple-select-label">Category</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="category"
                                            value={this.state.category}
                                            onChange={this.handleSystemChange}
                                            
                                        >
                                            {this.state.categories.map(category => {
                                        return <MenuItem value={category}>{category}</MenuItem>
                                    })}
                                        </Select>
                                        </FormControl>
                                
                                </Grid>
                                 <Grid item xs={6} sm={4} justify="center" alignItems="center" >
                                 <TextField
                                 required
                                id="outlined-required"
                                label="Required"
                                variant="outlined"
                                label="Alert Title"
                                value={this.state.title}
                                style = {{width:"100%"}}
                                 onChange = { (event) => {
                                            //console.log(event.target.value);
                                            this.setState({
                                                title : event.target.value
                                            })
                                        }}
                                />
                                </Grid>
                                 <Grid item xs={6} sm={12} justify="center" alignItems="center" >
                                 <TextField
                                 required
                                id="outlined-multiline-static"
                                label="Alert Description"
                                multiline
                                rows={4}
                                value={this.state.description}
                                variant="outlined"
                                style = {{width:"100%"}}
                                 onChange = { (event) => {
                                           // console.log(event.target.value);
                                            this.setState({
                                                description : event.target.value
                                            })
                                        }}
                                />
                                </Grid>
                                <Grid item xs={3} sm={2}  style={{textTransform: 'none'}} justify="center" alignItems="center">
                                 <Button variant="contained" style={{textTransform: 'none',width:'100%'}} color="primary" onClick = {this.handleSubmit}>
                                            Add Alert
                                            </Button>
                                </Grid>
                                           
                            </Grid>

                          
                    </ExpansionPanelDetails>
                    <br/>
                    <Typography className={classes.title} style ={{textAlignLast:'left',paddingLeft:'25px'}}component="div">
                         Current Alert Details
                    </Typography>
                  {this.state.data ? (  <ExpansionPanelDetails>
                      
                     
                        <Table className={classes.table} align="center" aria-label="simple table">
                                    
                            <TableHead>
                                <TableRow>
                                    <TableCell>Start Time</TableCell>
                                    <TableCell>End Time</TableCell>
                                    <TableCell>Application</TableCell>
                                    <TableCell>Alert Title</TableCell>
                                    <TableCell>Alert Description</TableCell>
                                    <TableCell>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                               
                               {
                                   this.state.alertsList.map( alert => {
                                   return  <TableRow key = {alert._id}>
                                    <TableCell >
                                  {new Date(parseInt(alert.start_time)).toUTCString()}
                                    </TableCell>
                                    <TableCell >
                                        {new Date(parseInt(alert.end_time)).toUTCString()}
                                    </TableCell>
                                    <TableCell style={{ padding: '' }} >
                                       
                                    {alert.application}
                                    </TableCell>
                                    <TableCell style={{ padding: '' }} align="justify">
                                       
                                    {alert.title}
                                    </TableCell>
                                    <TableCell style={{ padding: '' }} align="justify">
                                       
                                    {alert.description}
                                    </TableCell>
                                    <TableCell style={{ padding: '' }}>
                                    <Tooltip title="Copy" placement="right">
                                        <IconButton aria-label="edit" onClick = {() => this.copyAlert(alert)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"/></svg>
                                    </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Delete" placement="right">
                                    <IconButton aria-label="delete" onClick = {() => this.handleDelete(alert._id)}>
                                            <DeleteIcon />
                                    </IconButton>
                                    </Tooltip>
                                    </TableCell>
                                    
                                    
                                    
                                </TableRow>
                                   })
                               }
                                  
                                    
                               
                            </TableBody>
                        </Table>


                   </ExpansionPanelDetails>) : (<ExpansionPanelDetails>
                      
                     
                      <Table className={classes.table} align="center" aria-label="simple table">
                          <TableHead>
                              <TableRow>
                              <LinearProgress />
                              </TableRow>
                          </TableHead>
                          
                      </Table>


                 </ExpansionPanelDetails>) }

                </ExpansionPanel>
            </div>
        );
    }
}

export default withRouter(withStyles(styles)(Alertpanel));