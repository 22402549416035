import React from "react";
import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class Download extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    console.log(this.props);
  }
  render() {
   
        return (
          <ExcelFile
            element={<button>Download Analytics</button>}
            filename={
              this.props.bot +
              " Data" +
              " " +
              this.props.startDate +
              "-" +
              this.props.endDate
            }
            >
            <ExcelSheet
              dataSet={[
                {
                  columns: [this.props.bot + " - " + this.props.service],
                  data: [" "]
                },
                {
                  columns: ["User Transactions"],
                  data: [" "]
                },
                {
                  columns: this.props.queries[0],
                  data: this.props.queries.slice(1)
                },
                
                {
                  columns: [""],
                  data: [" "]
                },
                {
                  columns: ["Total Users"],
                  data: [""]
                },
                {
                  columns: this.props.users[0],
                  data: this.props.users.slice(1)
                },
                {
                  columns: [""],
                  data: [" "]
                },
                {
                  columns: ["New Users"],
                  data: [""]
                },
                {
                  columns: this.props.new_users[0],
                  data: this.props.new_users.slice(1)
                },
                {
                  columns: [""],
                  data: [" "]
                },
                {
                  columns: ["User Assist Categories"],
                  data: [" "]
                },
                {
                  columns: this.props.all_userassist_categories_d[0],
                  data: this.props.all_userassist_categories_d.slice(1)
                },
                {
                  columns: [""],
                  data: [" "]
                },
                {
                  columns: ["User Assist New Topics"],
                  data: [" "]
                },
                {
                  columns: this.props.user_assist_topics[0],
                  data: this.props.user_assist_topics.slice(1)
                },
                {
                  columns: [""],
                  data: [" "]
                },
                {
                  columns: ["Feedback"],
                  data: [" "]
                },
                {
                  columns: this.props.feedback[0],
                  data: this.props.feedback.slice(1)
                },
                {
                  columns: [""],
                  data: [" "]
                },
                {
                  columns: this.props.feedback_comments[0],
                  data: this.props.feedback_comments.slice(1)
                }
              ]}
              name="Analytics"
              />
          </ExcelFile>
        );
      }
      
    } 
  


export default Download;
