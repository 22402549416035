export function Athena(saml){
  [].forEach.call(document.querySelectorAll('.avaamo__chat__widget'), (x) => x.remove());
  [].filter.call(document.querySelectorAll('script'), x => x.src.indexOf('avaamo')!=-1).forEach(x => x.remove());
  var AvaamoChatBot=function(t){function o(t,o){var n=document.createElement("script");n.setAttribute("src",t);n.onload=o;document.body.appendChild(n)}return this.options=t||{},this.load=function(t){o(this.options.url,function(){window.Avaamo.addFrame();t&&"function"==typeof(t)&&t(window.Avaamo)})},this};
  var chatBox = new AvaamoChatBot({url: 'https://c8.avaamo.com/web_channels/p-s-t-df8bc5eb-b503-459b-94a9-3c097f101bed?action=demo&controller=web_channels&theme=avm-messenger&history=false&user_info='+saml});
  chatBox.load();
  var j=0;
    openBot(j)
 // setTimeout(function(){ window.Avaamo.openChatBox(); }, 2000);
}

export function SmartServiceDesk(saml){
    [].forEach.call(document.querySelectorAll('.avaamo__chat__widget'), (x) => x.remove());
    [].filter.call(document.querySelectorAll('script'), x => x.src.indexOf('avaamo')!=-1).forEach(x => x.remove());
    var AvaamoChatBot=function(t){function o(t,o){var n=document.createElement("script");n.setAttribute("src",t);n.onload=o;document.body.appendChild(n)}return this.options=t||{},this.load=function(t){o(this.options.url,function(){window.Avaamo.addFrame();t&&"function"==typeof(t)&&t(window.Avaamo)})},this}; 
    var chatBox = new AvaamoChatBot({url:'https://c8.avaamo.com/web_channels/p-s-t-e4a2e472-27e4-4cc9-8ce7-60bbbf988076?action=demo&controller=web_channels&theme=avm-messenger&history=false&user_info='+saml}); 
    chatBox.load(); 
    var j=0;
    openBot(j)
   // setTimeout(function(){ window.Avaamo.openChatBox(); }, 2000);
  }

export function ESBBot(saml){
    [].forEach.call(document.querySelectorAll('.avaamo__chat__widget'), (x) => x.remove());
    [].filter.call(document.querySelectorAll('script'), x => x.src.indexOf('avaamo')!=-1).forEach(x => x.remove());
    var AvaamoChatBot=function(t){function o(t,o){var n=document.createElement("script");n.setAttribute("src",t);n.onload=o;document.body.appendChild(n)}return this.options=t||{},this.load=function(t){o(this.options.url,function(){window.Avaamo.addFrame();t&&"function"==typeof(t)&&t(window.Avaamo)})},this};
    var chatBox = new AvaamoChatBot({url: 'https://c8.avaamo.com/web_channels/p-s-t-042f0b45-c884-4e71-aeb4-bca52feff98f?action=demo&controller=web_channels&theme=avm-messenger&history=false&user_info='+saml});
    chatBox.load();
    var j=0;
    openBot(j)
   // setTimeout(function(){ window.Avaamo.openChatBox(); }, 2000);
  }

  export function gob(saml) {
    [].forEach.call(document.querySelectorAll('.avaamo__chat__widget'), (x) => x.remove());
    [].filter.call(document.querySelectorAll('script'), x => x.src.indexOf('avaamo') != -1).forEach(x => x.remove());
    var AvaamoChatBot = function (t) { function o(t, o) { var n = document.createElement("script"); n.setAttribute("src", t); n.onload = o; document.body.appendChild(n) } return this.options = t || {}, this.load = function (t) { o(this.options.url, function () { window.Avaamo.addFrame(); t && "function" == typeof (t) && t(window.Avaamo) }) }, this };
    var chatBox = new AvaamoChatBot({ url:  process.env.REACT_APP_GOB_BOT_URL +saml });
    chatBox.load();
    var j=0;
    openBot(j)         
          
    }

    function openBot(j){
      setTimeout(function () {
                        try{
                       window.Avaamo.openChatBox();  
                 }catch(error){
                       console.log(error);
                       if(j<=10){
                       //  var i = j+1
                         openBot( j+1)
                  }
                 }
                 }, 500);
}