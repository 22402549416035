import React,  { Component } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import { withRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import 'typeface-poppins';
import jwt from 'jwt-simple';
import Box from '@material-ui/core/Box';
import "./cssfiles/css/main.css";
import "./cssfiles/css/util.css";
import Topbar from './Topbar';
import { ESBBot } from './scripts.js'


const backgroundShape = require('./cssfiles/images/bg02-main2.jpg');


const styles = theme => ({
  root: {
    backgroundImage:`url(${backgroundShape})`,
    backgroundSize: 'cover',
    opacity:".96",
    paddingTop: 100,
    paddingBottom: 216,
    width: "100%",
    margin: "0 auto",
    height:'100%',
    position:'fixed',
  },


  grid: {
    width: 1200,
    marginTop: 40,
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 20px)'
    }
  },
  paper: {
    padding: theme.spacing(3,5),
    textAlign: 'left',
    color: theme.palette.text.primary
  },
  rangeLabel: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(2)
  },
  topBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 32
  },
  outlinedButtom: {
    textTransform: 'uppercase',
    margin: theme.spacing(1)
  },
  actionButtom: {
    textTransform: 'uppercase',
    margin: theme.spacing(1),
    width: 152,
    background: "rgba(93,84,240,1)",
    background: "-webkit-linear-gradient(to right, rgba(0,168,255,1), rgba(185,0,255,1))",
    background: "-o-linear-gradient(to right, rgba(0,168,255,1), rgba(185,0,255,1))",
    background: "-moz-linear-gradient(to right, rgba(0,168,255,0.8), rgba(185,0,255,0.8))",
    background: "linear-gradient(to right, rgba(0,168,255,0.7), rgba(0, 200, 50, 0.5))",
  },
  blockCenter: {
    padding: theme.spacing(2),
    textAlign: 'center'
  },
  block: {
    padding: theme.spacing(2),
  },
  box: {
    marginBottom: 40,
    height: 65
  },
  inlining: {
    display: 'inline-block',
    marginRight: 10
  },
  buttonBar: {
    display: 'flex'
  },
  alignRight: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  noBorder: {
    borderBottomStyle: 'hidden'
  },
  loadingState: {
    opacity: 0.05
  },
  loadingMessage: {
    position: 'absolute',
    top: '40%',
    left: '40%'
  },
  info:{
    color:'#6600FF'
  }
});


class ITbot extends Component {


  constructor(props){
    super(props);
    this.state={
    learnMoredialog: false,
    getStartedDialog: false,
    open:false, 
    setOpen:false
    }
    this.handleClose = this.handleClose.bind(this);
    this.handleCloseAgree = this.handleCloseAgree.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
  }



  handleClickOpen(){
    this.setState({setOpen:true,open:true})  
  };


  handleClose() {
    this.setState({setOpen:false,open:false})
  };
   handleCloseAgree() {
    this.setState({setOpen:false,isITuser:true,open:false})
  };


  componentDidMount() {
    
    
  }


  openDialog = (event) => {
    this.setState({learnMoredialog: true});
  }


  openESBBOT = (event) => {
    let saml = this.props.saml
    var dec = jwt.decode(saml,"AvaamoChatBot20",true,"HS256");
    dec["uuid"] = dec["codeID"];
    var enc = jwt.encode(dec,"4f1b51c7-7c16-4277-9c91-fa4b64ce2243","HS256");
    ESBBot(enc);
     //  window.ESBBot(this.props.saml);
  }


  openSNOWbot = (event) => {

    let saml = this.props.saml
       window.SmartServiceDesk(saml);
  }


  dialogClose = (event) => {
    this.setState({learnMoredialog: false});
  }


  openGetStartedDialog = (event) => {
    this.setState({getStartedDialog: true});
  }


  closeGetStartedDialog = (event) => {
    this.setState({getStartedDialog: false});
  }
  componentWillUnmount(){
    this.closeBot()
  }
  closeBot(){
    [].forEach.call(document.querySelectorAll('.avaamo__chat__widget'), (x) => x.remove());
    [].filter.call(document.querySelectorAll('script'), x => x.src.indexOf('avaamo')!=-1).forEach(x => x.remove());
  }


  render() {
    const { classes } = this.props;
    if(!this.props.esb_access){
      return(
        <Grid></Grid>
      )
    }
    else{
    return (
      <React.Fragment>
        <CssBaseline />
        <Topbar alerts_access = {this.props.alerts_access} gob_access = {this.props.gob_access} firstName={this.props.firstName} lastName={this.props.lastName} codeID={this.props.codeID} emailID={this.props.emailID} dashboard_access={this.props.dashboard_access} admin_access={this.props.admin_access} esb_access={this.props.esb_access} />
        <div className={classes.root}>
          <Grid container justify="center" align='center'>
          <Grid container justify="center" align='center' xs={12} md={8} lg={12} xl={12}>
          <Typography variant="h5" component="h6" color='#FFFFFF' /*className={classes.info}*/ style={{"font-family": "Poppins-Bold",alignItems:'center',color:'#FFFFFF'}}>
                        Meet Your ESB Buddy
          </Typography>
          </Grid>
            <Grid spacing={6} alignItems="center" justify="center" container className={classes.grid}>
              <Grid item xs={12} md={4}>
                <Paper className={classes.paper} elevation="20">
                  <div className={classes.box}>
                    <Typography style={{textTransform: 'uppercase'}} color='secondary' gutterBottom>
                    <Box fontWeight="fontWeightBold">
                      IT Assist ESB Buddy
                      </Box>
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      I am here to help you with service status of ESB interfaces.
                    </Typography>
                  </div>
                  <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <Button onClick={this.openESBBOT} color='primary' variant="contained" className={classes.actionButtom}>
                      Launch
                    </Button>
                  </div>
                </Paper>
                </Grid>
              
              
            </Grid>
          </Grid>
         
        </div>
      </React.Fragment>
    )
    }
  }
}


export default withRouter(withStyles(styles)(ITbot));




















