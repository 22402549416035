import "core-js/stable";
import "regenerator-runtime/runtime";
import React, { Component } from "react";
import withStyles from "@material-ui/styles/withStyles";
import { withRouter } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import "typeface-poppins";
import Box from "@material-ui/core/Box";
import jwt from "jwt-simple";
import { Athena } from "./scripts.js";
import Link from "@material-ui/core/Link";
import Tooltip from "@material-ui/core/Tooltip";
import Carousel from "./Carousel";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import "./maincss.css";
import axios from 'axios';
import "./cssfiles/css/main.css";
import "./cssfiles/css/util.css";
import Topbar from "./Topbar";
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import Chip from '@material-ui/core/Chip';
import Grow from '@material-ui/core/Grow';

const backgroundShape = require("./cssfiles/images/bg02-main2.jpg");

const styles = theme => ({
  root: {
    paddingTop: 30,
    height: "auto",
    width: "100%",
    margin: "0 auto",
    position: "absolute",
    "& > svg": {
      margin: theme.spacing(2)
    }
  },

  grid: {
    width: 1200,
    marginTop: 40,
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 20px)"
    }
  },
  paper: {
    textAlign: "left",
    color: theme.palette.text.primary
  },
  rangeLabel: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: theme.spacing(2)
  },
  topBar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 32
  },
  outlinedButtom: {
    textTransform: "uppercase",
    margin: theme.spacing(1)
  },
  actionButtom: {
    textTransform: "uppercase",
    margin: theme.spacing(0, 3, 2, 1),
    width: 152,
    background: "rgba(93,84,240,1)",
    background:
      "-webkit-linear-gradient(to right, rgba(0,168,255,1), rgba(185,0,255,1))",
    background:
      "-o-linear-gradient(to right, rgba(0,168,255,1), rgba(185,0,255,1))",
    background:
      "-moz-linear-gradient(to right, rgba(0,168,255,0.8), rgba(185,0,255,0.8))",
    background:
      "linear-gradient(to right, rgba(0,168,255,0.7), rgba(0, 200, 50, 0.5))"
  },
  blockCenter: {
    padding: theme.spacing(2),
    textAlign: "center"
  },
  block: {
    padding: theme.spacing(2)
  },
  box2: {
    // marginBottom: 35,
    //height:200,
    // marginTop:10

    padding: theme.spacing(1, 4)
  },
  inlining: {
    display: "inline-block",
    marginRight: 10
  },
  buttonBar: {
    display: "flex"
  },
  alignRight: {
    display: "flex",
    justifyContent: "flex-end"
  },
  noBorder: {
    borderBottomStyle: "hidden"
  },
  loadingState: {
    opacity: 0.05
  },
  loadingMessage: {
    position: "absolute",
    top: "40%",
    left: "40%"
  },
  info: {
    color: "#6600FF"
  },
  paper1: {
    padding: theme.spacing(3, 5),
    textAlign: "left",
    background: theme.palette.error.dark
  },
  nested: {
    paddingLeft: theme.spacing(4),
    fontSize: "11px"
  },
  paperAnnouncements: {
    textAlign: "left",
    color: theme.palette.text.primary,
    height: "480px",
    background: "#FFFFFF",
    width: "90%"
  },
  titleBox: {
    padding: "10px 16px"
  },
  announcementsBox: {
    padding: theme.spacing(2, 3)
  },
  announcement: {
    padding: theme.spacing(1, 2),
    fontSize: "13px",
    textAlign: "justify"
  },
  description: {
    paddingLeft: "20px"
  },
  paperAd: {
    width: "90%",
    background: 'none',
    height:'30px'
  }
});

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      learnMoredialog: false,
      getStartedDialog: false,
      open: false,
      setOpen: false,
      itrequests: false,
      queries: false,
      alert:false,
      alertData:"",
      alertTitle:'',
      faded:false,
      totalQueries:"1111 Queries solved and counting....",
      data:{appear:1000,enter: 3000, exit: 1000 }
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleCloseAgree = this.handleCloseAgree.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
  }

  handleClickOpen() {
    this.setState({ setOpen: true, open: true });
  }

  handleClose() {
    this.setState({ setOpen: false, open: false });
  }
  handleCloseAgree() {
    this.setState({ setOpen: false, isITuser: true, open: false });
  }

  componentDidMount() {
    document.body.className = "main-component";
    this.getAlerts();
    this.getQueries();
  }

  getQueries = () => {

    axios.get("https://www.inside.athena.philips.com/login/totalQueries").then(response => {
          if(typeof(response.data.result) == "number"){
            let totalQueries = `${response.data.result} Queries solved and counting....`
            this.setState({
              totalQueries : totalQueries
            })
            this.fadedAnime(1000)

          }

            

        }).catch(error=> {
            console.log(error)

        });

  }

  fadedAnime(time) {
    setTimeout(() => {
     
      this.setState({
        faded:!this.state.faded
      })
              setTimeout(() => {
          
                    this.setState({
                      faded:!this.state.faded
                    })
                    //this.fadedAnime(4000)
          }, 8000);
      
    }, time);
  }

  getAlerts =()=> {

    axios.get(process.env.REACT_APP_ANNOUNCEMENT_API).then(response => {
        //console.log(response)
       // console.log(response.data.dashboardUsers)
        
        if(response.status===200){
          var currentDate = new Date()
          var activeAlerts = []
          var alertTitle = "";
          var alertDescription = "";
          var alertDescription = ""
          var applicationString = ""
          var applications = []
          response.data.map( alert => {

            var alertIsActive = currentDate.getTime() >= alert.start_time  && currentDate.getTime() <= alert.end_time 
            if(alertIsActive){
              var activity = alert.application.toLowerCase() === "sap" ? "Password Reset/Account Unlock" : alert.application.toLowerCase() === "akamai" ? "Akamai URL Flush/ MO Redirect" : alert.application.toLowerCase() === "windchill" ? "Windchill User License Reactivation" : "Operations"
              activeAlerts.push(alert)
              applications.push(activity) 
            }           
            
          })

         // console.log(activeAlerts)

          if(activeAlerts.length>0){
              var uniqueApplications = [...new Set(applications)]

                uniqueApplications.map((app,index,array)=>{
                  if(index === 0){
                    applicationString += app

                  }else{
                    if(index > 0 && index === array.length-1){
                      applicationString += ` and ${app}`
                    }else{
                      applicationString += `, ${app}`
                    }
                  }
                })
                alertDescription += `This is to inform you about a planned maintenance activity and during this period, <b>${applicationString}</b> will not be available for users of below mentioned applications.<br><br>`;
              activeAlerts.map((alert,index,arr) => {
                if(index === 0){
                  alertTitle += alert.title
                  alertDescription += alert.description
                }else{
                  if(index > 0 && index === arr.length-1){
                    alertTitle += ` and ${alert.title}`
                  }else{
                    alertTitle += `, ${alert.title}`
                  }
                  
                  alertDescription += `<br>${alert.description}`
                }
                
              })

              //console.log(alertTitle,alertDescription)
  
              alertTitle+= ' Unavailable.'
              this.setState({
                alertData:alertDescription,
                alertTitle:alertTitle,
                alert:true
              })
            }

       
        }

    }).catch( error => {
      console.log(error)
    });

}

  componentWillUnmount() {
    document.body.className = "common";
    this.closeBot();
  }

  closeBot() {
    [].forEach.call(document.querySelectorAll(".avaamo__chat__widget"), x =>
      x.remove()
    );
    [].filter
      .call(
        document.querySelectorAll("script"),
        x => x.src.indexOf("avaamo") != -1
      )
      .forEach(x => x.remove());
  }

  openDialog = event => {
    this.setState({ learnMoredialog: true });
  };

  openAthena = event => {
    let saml = this.props.saml;
    var dec = jwt.decode(
      saml,
      process.env.REACT_APP_LOGIN_JWT_SECRET,
      true,
      process.env.REACT_APP_LOGIN_JWT_SECRET_ALGORITHM
    );
    dec["uuid"] = dec["codeID"];
    // var enc = jwt.encode(dec, process.env.REACT_APP_AKAMI_BOT_SECRET, process.env.REACT_APP_LOGIN_JWT_SECRET_ALGORITHM);
    // Akami(enc)
    var enc = jwt.encode(
      dec,
      process.env.REACT_APP_ATHENA_BOT_SECRET,
      process.env.REACT_APP_LOGIN_JWT_SECRET_ALGORITHM
    );
    Athena(enc);
  };

  dialogClose = event => {
    this.setState({ learnMoredialog: false });
  };

  openGetStartedDialog = event => {
    this.setState({ getStartedDialog: true });
  };

  closeGetStartedDialog = event => {
    this.setState({ getStartedDialog: false });
  };

  showRequests = event => {
    let value = !this.state.itrequests;
    this.setState({
      itrequests: value,
      queries: false
    });
  };

  showQueries = event => {
    let value = !this.state.queries;
    this.setState({
      queries: value,
      itrequests: false
    });
  };

  closeAlertDialog = (event) => { this.setState({ alert: false }) }

  preventDefault = event => event.preventDefault();

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <CssBaseline />
        <Topbar
        alerts_access = {this.props.alerts_access}           
          firstName={this.props.firstName}
          lastName={this.props.lastName}
          codeID={this.props.codeID}
          emailID={this.props.emailID}
          dashboard_access={this.props.dashboard_access}
          admin_access={this.props.admin_access}
          esb_access={this.props.esb_access}
          gob_access = {this.props.gob_access}
        />
       <div className={classes.root}>
       <Dialog
                        open={this.state.alert}
                        onClose={this.closeAlertDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        >
                        <DialogTitle id="alert-dialog-title" style={{ fontFamily: "Lato,sans-serif", color: "#176498" }}>{"Alert"}</DialogTitle>
                        <DialogContent>
                        <DialogContentText id="alert-dialog-description" style={{ fontFamily: "Lato,sans-serif", color: "#000000",fontSize:'14px' }}>
                               <b>{this.state.alertTitle}</b> 
                        </DialogContentText>
                            <DialogContentText id="alert-dialog-description" style={{ fontFamily: "Lato,sans-serif", color: "#000000",fontSize:'14px' }}>
                                <div dangerouslySetInnerHTML={{__html: this.state.alertData }} />
                        </DialogContentText><br></br>
                        <DialogContentText id="alert-dialog-description" style={{ fontFamily: "Lato,sans-serif", color: "#000000",fontSize:'14px' }}>
                        For all other activities, please click on the continue button
                        </DialogContentText>
                        </DialogContent>
                        
                        <DialogActions>
                        <Button variant="outlined" color="primary"onClick={this.closeAlertDialog} style={{ fontFamily: "Lato,sans-serif" }}>
                                Continue
                        </Button>
                         {/* <Button  href="https://login.microsoftonline.com/common/wsfederation?wa=wsignout1.0" variant="outlined" color="primary" style={{ fontFamily: "Lato,sans-serif" }}>
                                Close Chatbot
                        </Button> */}
                        </DialogActions>
                    </Dialog>
                    <Grid container justify="center" align="center">
           <Grid
              container
              justify="center"
              align="center"
              xs={12}
              // md={12}
              // lg={12}
              // xl={12}
              style={{ paddingBottom: "10px" }}
            >
           <Grid item xs={3}>
        </Grid>
            <Grid
              container
              justify="center"
              align="center"
              xs={6}
            >
              <Typography
                variant="h5"
                component="h6"
                color="#FFFFFF"
                /*className={classes.info}*/ style={{
                  "font-family": "Poppins-Bold",
                  alignItems: "center",
                  color: "#FFFFFF"
                }}
              >
                Meet Your Athena Bot
              </Typography>
              </Grid>
               <Grid item xs={3}>
            
             <Grow
          in={this.state.faded}
          style={{ transformOrigin: '0 0 0' }}
          {...(this.state.faded ? { timeout: 2000 } : {timeout: 2000})}
        >
          <Paper className = {classes.paperAd} elevation={0} >
                  <Chip size="large"style={{background:"#008000",color:'#FFFFFF',fontWeight:'bold', fontSize:'14px'}}  elevation={3} icon={<QuestionAnswerIcon fontSize = 'medium' style={{color:"#FFFFFF"}}/>} label={this.state.totalQueries} />
                
              </Paper>
        </Grow>
            </Grid>
            </Grid>

            <Grid container justify="center" align="center" xs={11} md={10}>
              <Grid container direction="row" justify="center" align="center">
                <Grid item justify="center" xs={12} md={5}>
                  <Grid style={{ width: "97%" }}>
                    <Paper className={classes.paperAnnouncements}>
                      <Grid xs={12} md={12} style={{ background: "#1565c0" }}>
                        <Grid>
                          <Typography
                            style={{ textTransform: "fontWeightBold" }}
                            style={{ color: "#FFFFFF", fontSize: "14.5px" }}
                            gutterBottom
                          >
                            <Box
                              className={classes.titleBox}
                              fontWeight="fontWeightBold"
                            >
                              Announcements
                            </Box>
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid xs={12} md={12} style={{}}>
                        <Carousel />
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>

                <Grid justify="center" align="center" xs={2} />

                <Grid item justify="center" xs={12} md={5}>
                  <Grid container alignItems="center" justify="center">
                    <Grid xs={12} md={11}>
                      <Paper className={classes.paper} elevation="20">
                        <Grid xs={12} md={12} style={{ background: "#1565c0" }}>
                          <Typography
                            style={{ textTransform: "fontWeightBold" }}
                            style={{ color: "#FFFFFF", fontSize: "14.5px" }}
                            gutterBottom
                          >
                            <Box
                              className={classes.titleBox}
                              fontWeight="fontWeightBold"
                            >
                              Athena - Smart Service Desk Assistant
                            </Box>
                          </Typography>
                        </Grid>
                        <div className={classes.box2}>
                          <Typography
                            variant="body6"
                            gutterBottom
                            style={{ fontSize: "12.5px" }}
                          >
                            I am here to help you with <b>IT requests</b> for
                            the below services:
                          </Typography>
                          <br />
                          {!this.state.queries ? (
                            <div style={{ height : "20%" }}>
                              
                              <b style={{ fontSize: "12.5px" }}>Windchill User License Reactivation </b>
                              <br/>
                              
                              
                              <Typography
                                variant="body6"
                                className={classes.description}
                              >
                                <b style={{ fontSize: "11.5px" }}>I2M</b>
                                <ul>
                                  <li className={classes.nested}>
                                    • PIL cPLM
                                  </li>
                                </ul>
                              </Typography>
                              <b style={{ fontSize: "12.5px" }}>Akamai Operations </b>
                              <br/>
                              
                              <Typography variant="body6" style={{ fontSize: "11.5px",paddingLeft: "10px" }} >
                              <b>Akamai URL Flush and MO Redirect</b>
                              </Typography>
                              <br/>
                              <Typography
                              variant="body6"
                              className={classes.description}
                              >
                              <b style={{ fontSize: "11.5px" }}>Digital</b>
                                <ul style={{ marginBottom : "0rem"}}>
                                  <li className={classes.nested}>
                                    • Online Marketing
                                  </li>
                                </ul>
                              </Typography>
                              <br/>
                              
                              {/* <Typography
                                variant="body6"
                                className={classes.description}
                              >
                                
                              </Typography> */}
                            </div>
                          ) : (
                            <div />
                          )}
                           <Grid
                            container
                            direction="row"
                            justify="space-between"
                            alignItems="end"
                          >
                            {/* <b style={{ fontSize: "12.5px" }}>
                              Password Reset and Account Unlock
                              {/* M2O Account Reactivation
                            </b> */}

                            {this.state.queries ? (
                              <Typography
                                onClick={this.showRequests}
                                style={{ marginRight: "45px",fontFamily: "Lato, sans-serif" }}
                              >
                                <Tooltip
                                  title="Click here to see more Services"
                                  placement="top"
                                  interactive
                                >
                                  <Link
                                    href="#"
                                    color="secondary"
                                    onClick={this.preventDefault}
                                    style={{ fontSize: "11px",fontFamily: "Lato, sans-serif" }}
                                  >
                                    Read More
                                  </Link>
                                </Tooltip>
                              </Typography>
                            ) : (
                              <div />
                            )}
                          </Grid>
                          {this.state.itrequests ? (
                            <div>
                              <b style={{ fontSize: "12.5px" }}>
                              Password Reset and Account Unlock
                              {/* M2O Account Reactivation */}
                              </b><br></br>

                              <Typography
                                variant="body6"
                                className={classes.description}
                              >
                                <b style={{ fontSize: "11.5px" }}>M2O</b>
                                <ul style={{ marginBottom : "0rem"}}>
                                    <li className={classes.nested}>
                                  • CP M2O CHANNEL MANAGEMENT (B2B Sales Cloud (Salesforce cCRM))
                                </li>
                                </ul>
                              </Typography>
                  
                              <Typography
                                variant="body6"
                                className={classes.description}
                              >
                                <b style={{ fontSize: "11.5px" }}>O2C</b>
                                <ul style={{ marginBottom : "0rem"}}>
                                    <li className={classes.nested}>
                                  • PIL O2C ERP (WPP)
                                </li>
                                <li className={classes.nested}>
                                  • CL DAP KERNEL (SPC)
                                </li>
                                <li className={classes.nested}>
                                  • HC HHS SAP KERNEL (PRD)
                                </li>
                                <li className={classes.nested}>
                                  • PIL O2C Global Trade (GTP)
                                </li>
                                </ul>
                              </Typography>
                              <Typography
                                variant="body6"
                                className={classes.description}
                              >
                                <b style={{ fontSize: "11.5px" }}>Functions</b>
                                <ul style={{ marginBottom : "0rem"}}>
                                  <li className={classes.nested}>
                                    • SAP BW ONEBI/CLBW/BPC (WPC)
                                  </li>
                                  <li className={classes.nested}>
                                    • CF ONE BCS/EFR (CWP)
                                  </li>
                                  <li className={classes.nested}>
                                    • CF SAP FOR TREASURY (CTP)
                                  </li>
                                </ul>
                              </Typography>
                              <Typography
                                variant="body6"
                                className={classes.description}
                              >
                                <b style={{ fontSize: "11.5px" }}>I2M</b>
                                <ul>
                                  <li className={classes.nested}>
                                    • CT KERNEL (TEP)
                                  </li>
                                </ul>
                              </Typography>
                            </div>
                          ) : (
                            <div />
                          )}

                          {!this.state.queries ? (
                            <Typography
                              variant="body6"
                              className={classes.description}
                            >
                              <b style={{ fontSize: "12.5px" }}>
                              {/* Password Reset and Account Unlock */}
                                B2B Sales Cloud (Salesforce cCRM) Account Reactivation
                              </b><br></br>
                              {/* <b style={{ fontSize: "11.5px" }}>I2M</b> */}
                              <b style={{ fontSize: "11.5px", paddingLeft : "30px" }}>M2O</b>
                              <ul style={{ marginBottom : "0rem"}}>

                                <li className={classes.nested}>
                                  <Grid
                                    container
                                    direction="row"
                                    justify="space-between"
                                    alignItems="end"
                                  >
                                    {/* • CT KERNEL (TEP) */}
                                    • CP M2O CHANNEL MANAGEMENT (B2B Sales Cloud (Salesforce cCRM))
                                    <Typography
                                      onClick={this.showRequests}
                                      style={{ marginRight: "45px" }}
                                    >
                                      {!this.state.itrequests ? (
                                        <Tooltip
                                          title="Click here to see more Services"
                                          interactive
                                        >
                                          <Link
                                            href="#"
                                            placement="top"
                                            color="secondary"
                                            onClick={this.preventDefault}
                                            style={{ fontSize: "11px",fontFamily: "Lato, sans-serif" }}
                                          >
                                            Read More
                                          </Link>
                                        </Tooltip>
                                      ) : (
                                        <Link
                                            href="#"
                                            color="secondary"
                                            onClick={this.preventDefault}
                                            style={{ fontSize: "11px",fontFamily: "Lato, sans-serif" }}
                                          >
                                            Read Less
                                          </Link>
                                      )}
                                    </Typography>
                                  </Grid>
                                </li>
                              </ul>
                            </Typography>
                          ) : (
                            <div />
                          )}
                          <br />
                          <Typography
                            variant="body6"
                            gutterBottom
                            style={{ fontSize: "12.5px" }}
                          >
                            I am here to help you with <b>Queries</b> for the
                            below services:
                          </Typography>
                          <br />
                          {this.state.queries ? (
                            <div>
                              <Typography
                                variant="body6"
                                className={classes.description}
                              >
                                <b style={{ fontSize: "11.5px" }}>Integration</b>
                                <ul>
                                <li className={classes.nested}>
                                    • HT EDI as a Service
                                  </li>
                                </ul>
                              </Typography>
                              <Typography
                                variant="body6"
                                className={classes.description}
                              >
                                <b style={{ fontSize: "11.5px" }}>Digital</b>
                                <ul>
                                  <li className={classes.nested}>
                                    • Philips Store
                                  </li>
                                  <li className={classes.nested}>
                                    • PIL Online Marketing
                                  </li>
                                  <li className={classes.nested}>
                                    • PIL Content Management
                                  </li>
                                </ul>
                              </Typography>
                              <Typography
                                variant="body6"
                                className={classes.description}
                              >
                                <b style={{ fontSize: "11.5px" }}>Functions</b>
                                <ul>
                                  <li className={classes.nested}>
                                    • CF CONCUR (TEM)
                                  </li>
                                  <li className={classes.nested}>
                                    • CF SRM &amp; ARIBA PIL Self Service
                                    Procurement
                                  </li>
                                  <li className={classes.nested}>
                                    • CF Information Management
                                  </li>
                                </ul>
                              </Typography>
                              <Typography
                                variant="body6"
                                className={classes.description}
                              >
                                <b style={{ fontSize: "11.5px" }}>I2M</b>
                                <ul>
                                  <li className={classes.nested}>• PIL cPLM</li>
                                  <li className={classes.nested}>
                                    • HC eMatrix
                                  </li>
                                  <li className={classes.nested}>
                                    • PIL Clarity
                                  </li>
                                  <li className={classes.nested}>
                                    • PIL I2M Planisware
                                  </li>
                                </ul>
                              </Typography>
                              <Typography
                                variant="body6"
                                className={classes.description}
                              >
                                <b style={{ fontSize: "11.5px" }}>M2O</b>
                                <ul>
                                  <li className={classes.nested}>
                                    • CP M2O CHANNEL MANAGEMENT (B2B Sales Cloud (Salesforce cCRM))
                                  </li>
                                </ul>
                              </Typography>
                            </div>
                          ) : (
                            <div />
                          )}

                           <Typography
                            variant="body6"
                          >
                          <Grid
                            container
                            direction="row"
                            justify="space-between"
                            alignItems="end"
                          >
                            
                             <b className={classes.description} style={{ fontSize: "11.5px" }}>O2C</b>
                           

                            {this.state.itrequests ? (
                              <Typography
                                onClick={this.showQueries}
                                style={{ marginRight: "45px" }}
                              >
                                <Tooltip
                                  title="Click here to see more Services"
                                  placement="top"
                                  interactive
                                >
                                  <Link
                                    href="#"
                                    color="secondary"
                                    onClick={this.preventDefault}
                                    style={{ fontSize: "11px",fontFamily: "Lato, sans-serif" }}
                                  >
                                    Read More
                                  </Link>
                                </Tooltip>
                              </Typography>
                            ) : (
                              <div />
                            )}
                          </Grid>
                            
                            {!this.state.itrequests ? (
                              <ul>
                              <li className={classes.nested}>
                                    • SAP MES
                                  </li>
                                  <li className={classes.nested}>
                                    • HC HHS SAP BUSINESS ONE
                                  </li>
                                <li className={classes.nested}>
                                  <Grid
                                    container
                                    direction="row"
                                    justify="space-between"
                                    alignItems="end"
                                  >
                                    • PIL O2C ERP
                                    <Typography
                                      onClick={this.showQueries}
                                      style={{ marginRight: "45px" }}
                                    >
                                      {!this.state.queries ? (
                                        <Tooltip
                                          title="Click here to see more Services"
                                          placement="top"
                                          interactive
                                        >
                                          <Link
                                            href="#"
                                            color="secondary"
                                            onClick={this.preventDefault}
                                            style={{ fontSize: "11px",fontFamily: "Lato, sans-serif" }}
                                          >
                                            Read More
                                          </Link>
                                        </Tooltip>
                                      ) : (
                                        <Link
                                          href="#"
                                          color="secondary"
                                          style={{
                                            fontSize: "11px",
                                            fontFamily: "Lato, sans-serif"
                                          }}
                                          onClick={this.preventDefault}
                                        >
                                          Read Less
                                        </Link>
                                      )}
                                    </Typography>
                                  </Grid>
                                </li>
                              </ul>
                            ) : (
                              <div />
                            )}
                          </Typography>
                        </div>
                        <div
                          className={classes.launchButton}
                          style={{
                            display: "flex",
                            justifyContent: "flex-end"
                          }}
                        >
                          <Button
                            onClick={this.openAthena}
                            color="primary"
                            variant="contained"
                            className={classes.actionButtom}
                          >
                            Launch
                          </Button>
                        </div>
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(withStyles(styles)(Main));
