import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { FormGroup } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import axios from 'axios';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Popover from '@material-ui/core/Popover';

const styles = theme => ({
    root: {
        width: '100%'
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    form: {
        width: '100%',
        margin: '0px',
        display: 'inline-flex',
        '& > *': {
            margin: theme.spacing(1),
            width: '100%',
        },
    },
    buttonGrid: {
        alignItems: 'center',
        display: 'flex'
    },
    inputfield: {
        width: '100px'
    },
    table: {
        minWidth: 650,
    },
    textField:{
    width: '400px'
    }
});

class SimpleExpansionPanel extends Component {

    constructor(props) {

        super(props);
        this.state = {
            learnMoredialog: false,
            emailID: "",
            dashboardAdmin: [],
            esbUsers: [],
            gobAdmin:[],
            alertAdmin:[],
            esbemailID:'',
            gobemailID:'',
            alertemailID:'',
            data:false,
            openDeleteDialog:false,
            selected:'',
            esbUser:'esbUsers',
            dashboardUsers:'dashboardUsers',
            gobUsers:'GOBdashboardUsers',
            alertUsers:'AnnouncementUsers',
            selectedType:'',
            currentEdit:''


        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleDialogClose = this.handleDialogClose.bind(this);
        this.handleDialogOpen = this.handleDialogOpen.bind(this);
        this.ValidateEmail = this.ValidateEmail.bind(this)
    }





    componentDidMount() {

        this.getData()

    }



    getData() {

        axios.get(process.env.REACT_APP_ACCESS_DATA_API,{auth: {
             username: process.env.REACT_APP_ACCESS_DATA_API_AUTH_USER,
             password: process.env.REACT_APP_ACCESS_DATA_API_AUTH_PASS
        }}).then(response => {
            //console.log(response)
            //console.log(response.data.dashboardUsers)
            if(response.status===502){

            }
            if(response.status===200){
            this.setState({
                dashboardAdmin: response.data.dashboardUsers,
                esbUsers: response.data.esbUsers,
                gobAdmin:response.data.GOBdashboardUsers,
                alertAdmin:response.data.AnnouncementUsers,
                data:true

            });
            }
            else{
              this.setState({
                data:false,
                
              })
            }

        });

    }

    ValidateEmail = () => { 
            if (/^\w+([\.-]?\w+)*@philips.com+$/.test(this.state.emailID)) 
          { return (true) }  
          return (false)
          }
             ValidateesbEmail = () => { 
            if (/^\w+([\.-]?\w+)*@philips.com+$/.test(this.state.esbemailID)) 
          { return (true) }  
          return (false)
          }
          Validategobemail = () => { 
            if (/^\w+([\.-]?\w+)*@philips.com+$/.test(this.state.gobemailID)) 
          { return (true) }  
          return (false)
          }

          Validatealertemail = () => { 
            if (/^\w+([\.-]?\w+)*@philips.com+$/.test(this.state.alertemailID)) 
          { return (true) }  
          return (false)
          }

   
    handleSubmit(event) {
        
         let currentEdit = this.state.currentEdit
         let email = currentEdit == "dashboardUsers" ? this.state.emailID : currentEdit == "esbUsers" ? this.state.esbemailID :  currentEdit == "GOBdashboardUsers" ? this.state.gobemailID : this.state.alertemailID
        console.log(email)

          if(this.ValidateEmail() || this.ValidateesbEmail() || this.Validategobemail() || this.Validatealertemail() ){
             axios.post(process.env.REACT_APP_ACCESS_DATA_API + '/addUser',
            {
                type: currentEdit,
                email: email
            },
            {auth: {
             username: process.env.REACT_APP_ACCESS_DATA_API_AUTH_USER,
             password: process.env.REACT_APP_ACCESS_DATA_API_AUTH_PASS
             }},
            {
                headers: {
                    'Content-Type': 'application/json'
                }

            }).then(resp => {
              //console.log(resp)
                if(resp.data.status==='success'){
                this.setState({ emailID: '',esbemailID:'',gobemailID:'',data:false })
              //  console.log(this.state)
                alert('User added successfully')
                this.getData()
                }
                else{
                    if(resp.data.status==='fail'){
                        alert("The user might have the required access. Please check the list and try again")
                    }
                    else{
                  alert('Please try again')
                    }
                }
                
            }).catch(error => {
                console.log(error);
            });

          }

          else{
            alert('Invalid Email Format')
          }

       
        event.preventDefault();

    }

    handleDelete(type) {
        let element = this.state.selected;
        let selected = this.state.selectedType
 
        axios.post(process.env.REACT_APP_ACCESS_DATA_API + '/deleteUser' ,
        {
            type: selected,
            email: element
        },
        {auth: {
             username: process.env.REACT_APP_ACCESS_DATA_API_AUTH_USER,
             password: process.env.REACT_APP_ACCESS_DATA_API_AUTH_PASS
             }},
        {
            headers: {
                'Content-Type': 'application/json'
            }

        }).then(resp => {
           // console.log(resp.data);
            alert('User Deleted Successfully')
             this.setState({
              selected:'',
              openDeleteDialog:false,
              data:false

            })
            this.getData()
        }).catch(error => {
            console.log(error);
        });

        //    axios.delete("http://localhost:3001/superAdmin/" + id).then(response => {
        //   alert("Deleted Sucessfully");
        //   this.getData();

        // });  
       // console.log(this.state)

    }

    handleDialogClose(){
      this.setState({
        openDeleteDialog:false
      })
    }

    handleDialogOpen(element,type){
      this.setState({
        openDeleteDialog:true,
        selected:element,
        selectedType:type
      })

    }


    render() {

        const { classes } = this.props;

        return (
                
            <div className={classes.root}>
                  <Dialog
                    open={this.state.openDeleteDialog}
                    onClose={this.handleDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">{"Are you sure you want to delete ?"}</DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        "{this.state.selected}" will be deleted.
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={this.handleDialogClose} color="primary">
                        Cancel
                      </Button>
                      <Button onClick={this.handleDelete} color="primary">
                        Delete
                      </Button>
                    </DialogActions>
                  </Dialog>
                <ExpansionPanel>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.heading}><b>Dashboard Admin Group</b></Typography>
                    </ExpansionPanelSummary>
                   <ExpansionPanelDetails >
                        <Grid container style={{}}spacing={1}>
                            <form className={classes.form} Validate autoComplete="off">
                                <Grid style={{ margin: '0px' }}  xs={8}>
                                    <TextField
                                        required
                                        className={classes.inputfield}
                                        id="standard-email-input"
                                        label="Enter the Philips ID"
                                        className={classes.textField}
                                        type="email"
                                        name="dashboardUsers"
                                        autoComplete="email"
                                        margin="normal"
                                        for='email'
                                        value={this.state.emailID}
                                        onChange={e => {


                                            let email = e.target.value;
                                            let currentEdit = e.target.name
                                            this.setState({ emailID: email,currentEdit:currentEdit });
                                        }}

                                    />
                                </Grid>
                                <Grid item xs={40} style={{ margin: '0px' }} className={classes.buttonGrid}>
                                    <Button variant="contained" color="primary" className={classes.button} type="submit" onClick={this.handleSubmit} >
                                        Add
                                            </Button>
                                </Grid>
                            </form>
                        </Grid>


                    </ExpansionPanelDetails>
                  {this.state.data ? (  <ExpansionPanelDetails>
                        <Table className={classes.table} align="center" aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell>Email Id</TableCell>
                                    <TableCell>Delete</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.dashboardAdmin.map((element,index) => (
                                  
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            {index + 1}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {element}
                                        </TableCell>
                                        <TableCell style={{ padding: 'inherit' }}>
                                            <IconButton aria-label="delete" id='23' className={classes.margin} onClick={this.handleDialogOpen.bind(this,element,this.state.dashboardUsers)}>
                                                <DeleteIcon />
                                            </IconButton>

                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>


                   </ExpansionPanelDetails>) : (<CircularProgress />) }

                </ExpansionPanel>
                <ExpansionPanel>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.heading}><b>IT Analyst Group</b></Typography>
                    </ExpansionPanelSummary>
            
                    <ExpansionPanelDetails >
                        <Grid container spacing={2}>
                            <form className={classes.form} noValidate autoComplete="off">
                                <Grid style={{ margin: '0px' }} item xs={8}>
                                    <TextField
                                        required
                                        className={classes.inputfield}
                                        id="standard-email-input"
                                        label="Enter the Philips ID"
                                        className={classes.textField}
                                        type="email"
                                        name="esbUsers"
                                        autoComplete="email"
                                        margin="normal"
                                        variant="standard"
                                        value={this.state.esbemailID}
                                        onChange={e => {


                                            let email = e.target.value;
                                            let currentEdit = e.target.name
                                            this.setState({ esbemailID: email,currentEdit:currentEdit });
                                        }}

                                    />
                                </Grid>
                                <Grid item xs={40} style={{ margin: '0px' }} className={classes.buttonGrid}>
                                    <Button variant="contained" color="primary" className={classes.button} type="submit" onClick={this.handleSubmit} >
                                        Add
                                            </Button>
                                </Grid>
                            </form>
                        </Grid>


                    </ExpansionPanelDetails>

                    {this.state.data ? ( <ExpansionPanelDetails>

                        <Table className={classes.table} align="center" aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell>Email Id</TableCell>
                                    <TableCell>Delete</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.esbUsers.map((element,index) => (
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            {index + 1}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {element}
                                        </TableCell>
                                        <TableCell style={{ padding: 'inherit' }}>
                                            <IconButton aria-label="delete" className={classes.margin} onClick={this.handleDialogOpen.bind(this,element,this.state.esbUser)}>
                                                <DeleteIcon />
                                            </IconButton>

                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>

                    </ExpansionPanelDetails> ) : (<CircularProgress />) }

                </ExpansionPanel>
                
                     <ExpansionPanel>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.heading}><b>GOD Dashboard Group</b></Typography>
                    </ExpansionPanelSummary>
            
                    <ExpansionPanelDetails >
                        <Grid container spacing={2}>
                            <form className={classes.form} noValidate autoComplete="off">
                                <Grid style={{ margin: '0px' }} item xs={8}>
                                    <TextField
                                        required
                                        className={classes.inputfield}
                                        id="standard-email-input"
                                        label="Enter the Philips ID"
                                        className={classes.textField}
                                        type="email"
                                        name="GOBdashboardUsers"
                                        autoComplete="email"
                                        margin="normal"
                                        variant="standard"
                                        value={this.state.gobemailID}
                                        onChange={e => {
                                            let email = e.target.value;
                                            let currentEdit = e.target.name
                                            this.setState({ gobemailID: email,currentEdit:currentEdit });
                                        }}

                                    />
                                </Grid>
                                <Grid item xs={40} style={{ margin: '0px' }} className={classes.buttonGrid}>
                                    <Button variant="contained" color="primary" className={classes.button} type="submit" onClick={this.handleSubmit} >
                                        Add
                                            </Button>
                                </Grid>
                            </form>
                        </Grid>


                    </ExpansionPanelDetails>

                    {this.state.data ? ( <ExpansionPanelDetails>

                        <Table className={classes.table} align="center" aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell>Email Id</TableCell>
                                    <TableCell>Delete</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.gobAdmin.map((element,index) => (
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            {index + 1}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {element}
                                        </TableCell>
                                        <TableCell style={{ padding: 'inherit' }}>
                                            <IconButton aria-label="delete" className={classes.margin} onClick={this.handleDialogOpen.bind(this,element,this.state.gobUsers)}>
                                                <DeleteIcon />
                                            </IconButton>

                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>

                    </ExpansionPanelDetails> ) : (<CircularProgress />) }
                    </ExpansionPanel>
                     <ExpansionPanel>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.heading}><b>Alert Dashboard Group</b></Typography>
                    </ExpansionPanelSummary>
            
                    <ExpansionPanelDetails >
                        <Grid container spacing={2}>
                            <form className={classes.form} noValidate autoComplete="off">
                                <Grid style={{ margin: '0px' }} item xs={8}>
                                    <TextField
                                        required
                                        className={classes.inputfield}
                                        id="standard-email-input"
                                        label="Enter the Philips ID"
                                        className={classes.textField}
                                        type="email"
                                        name="AnnouncementUsers"
                                        autoComplete="email"
                                        margin="normal"
                                        variant="standard"
                                        value={this.state.alertemailID}
                                        onChange={e => {
                                            let email = e.target.value;
                                            let currentEdit = e.target.name
                                            this.setState({ alertemailID: email,currentEdit:currentEdit });
                                        }}

                                    />
                                </Grid>
                                <Grid item xs={40} style={{ margin: '0px' }} className={classes.buttonGrid}>
                                    <Button variant="contained" color="primary" className={classes.button} type="submit" onClick={this.handleSubmit} >
                                        Add
                                            </Button>
                                </Grid>
                            </form>
                        </Grid>


                    </ExpansionPanelDetails>

                    {this.state.data ? ( <ExpansionPanelDetails>

                        <Table className={classes.table} align="center" aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell>Email Id</TableCell>
                                    <TableCell>Delete</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.alertAdmin.map((element,index) => (
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            {index + 1}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {element}
                                        </TableCell>
                                        <TableCell style={{ padding: 'inherit' }}>
                                            <IconButton aria-label="delete" className={classes.margin} onClick={this.handleDialogOpen.bind(this,element,this.state.alertUsers)}>
                                                <DeleteIcon />
                                            </IconButton>

                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>

                    </ExpansionPanelDetails> ) : (<CircularProgress />) }
                    </ExpansionPanel>
            </div>
        );
    }
}

export default withRouter(withStyles(styles)(SimpleExpansionPanel));