import React, { Component, createRef } from "react";
import withStyles from "@material-ui/styles/withStyles";
import { withRouter } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Topbar from "./Topbar";
import axios from "axios";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import NativeSelect from '@material-ui/core/NativeSelect';
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
//const backgroundShape = require('./cssfiles/images/bg03.jpg');
import "./cssfiles/css/main.css";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import Plot from "react-plotly.js";
import Download from "./Gobdownload";
import Button from "@material-ui/core/Button";

const styles = theme => ({
  root: {
    flexGrow: 1,
    background:
      "-webkit-linear-gradient(to right, #83a4d4, #b6fbff)" /* Chrome 10-25, Safari 5.1-6 */,
    background: "linear-gradient(to left, #62e83430, #97fa9e7d)",
    overflow: "hidden",
    //background: `url(${backgroundShape}) no-repeat`,
    backgroundSize: "cover",
    backgroundPosition: "0 400px",
    paddingBottom: 50
    //border:"dashed"
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),

    color: theme.palette.text.secondary
  },
  formControl: {
    margin: theme.spacing(2),
    marginLeft: "0px",
    fullWidth: true
  },
  title: {
    display: "block",
    fontFamily: "Poppins-Bold",
    fontSize: "25px",
    color: theme.palette.primary.main,
    lineHeight: 1.2,
    textAlign: "center",
    padding: "20px"
  },
  subTitle: {
    display: "block",
    fontFamily: "Poppins-Bold",
    fontSize: "20px",
    color: "#333333",
    lineHeight: 1.2,
    textAlign: "center"
  },
  selectors: {
    flex: "inherit"
    //border:"double"
  },
  downloadButton: {
    flex: "inherit",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    paddingLeft: "5px"
  },
  dateControl: {
    margin: theme.spacing(1),
    marginLeft: "0px",
    padding: "2px"
  },
  apply: {
    paddingTop: "35px",
    flex: "inherit"
  }
});

function getDataForGraph(
  from_date,
  to_date,
  kernel,
  allCategories
) {
      try {
                    var status = 0;
                    var categories = [];
                    console.log(kernel,kernel =="All Categories")
                    if(kernel =="All Categories"){
                      categories = allCategories.filter(e => e !== "All Categories");
                    }else{
                      categories.push(kernel)
                    }
                    
                    return axios.post(
                         "https://www.inside.athena.philips.com/login/GOBTransaction/getConsolidatedResponse",
                          {
                            from_date: from_date,
                            to_date: to_date,
                            categories: categories
                          },
                          {
                            headers: {
                              "Content-Type": "application/json"
                            }
                          }
                        ).then(function(response) {
                          console.log(response)
                       
                       

                        var transactions = [[],[]];
                        var transactions_d = [["Transaction Type"],["User Assitst"]]
                        var users_d = [["Transaction Type"],["User Assitst"]]
                        var users = [[],[]];
                        var feedback = [[],["Happy","Neutral","Unhappy"]];
                        var feedback_d = [["Feedback"],["Happy"],["Neutral"],["Unhappy"]];
                        var feedback_comments = [["Feedback Comment"]];
                        var transactions_count = 0,user_count = 0,new_user_count=0,happy_count = 0,unhappy_count=0,neutral_count=0;
                        var category_list = [];
                        var all_userassist_categories_d = [["Category"]];
                        var all_userassist_categories= [];
                        var user_assist_topics = [];
                        var user_assist_topic_list = [];
                        var new_users_d = [["Transaction Type"],["User Assitst"]];
                        try {
                            if(true){

                                response.data.map( category => {
                                    var happy_d=0,neutral_d=0,unhappy_d=0;
                                    console.log(category)
                                    transactions[0].push(category.category)
                                    transactions[1].push(category.transactions.user_assist)
                                    transactions_d[0].push(category.category)
                                    transactions_d[1].push(category.transactions.user_assist)
                                    users[0].push(category.category)
                                    users[1].push(category.unique_user_count.user_assist)
                                    users_d[0].push(category.category)
                                    users_d[1].push(category.unique_user_count.user_assist)
                                    new_users_d[0].push(category.category)
                                    new_users_d[1].push(category.new_user_count.user_assist)
                                    feedback_d[0].push(category.category)
                                    transactions_count += category.transactions.user_assist;
                                    user_count += category.unique_user_count.user_assist;
                                    new_user_count += category.unique_user_count.user_assist;
                                    category.feedback_comments.map(comment => {
                                        feedback_comments.push([comment])
                                    })
                                    all_userassist_categories_d[0].push(category.category)
                                    Object.keys(category.user_assist_sub_catgeory).map(function(key) {
                                      if(!category_list.includes(key)){
                                        category_list.push(key);
                                        all_userassist_categories.push([key])
                                      }
                                        
                                    });
                                    
                                    Object.keys(category.user_assist_messages).map(function(key) {
                                      console.log("messages-------",category.user_assist_messages,"condition",!user_assist_topic_list.includes(key),user_assist_topic_list)
                                      if (!user_assist_topic_list.includes(key)) {
                                        user_assist_topic_list.push(key);
                                        user_assist_topics.push([
                                          key,
                                          category.user_assist_messages[key].toString()
                                        ]);
                                      } else {
                                        user_assist_topics[
                                          user_assist_topic_list.indexOf(key)
                                        ][1] = user_assist_topics[
                                          user_assist_topic_list.indexOf(key)
                                        ][1].concat(", "+category.user_assist_messages[key].toString());
                                      }
                                    });

                                     Object.keys(category.feedback).map(function(key) {
                                       if(key.toUpperCase() == "HAPPY"){
                                           happy_count += category.feedback[key]
                                           happy_d += category.feedback[key]
                                       }else if(key.toUpperCase() == "NEUTRAL"){
                                           neutral_count += category.feedback[key]
                                           neutral_d += category.feedback[key]
                                       }else if(key.toUpperCase() == "UNHAPPY"){
                                           unhappy_count += category.feedback[key]
                                           unhappy_d += category.feedback[key]
                                       }
                                    });
                                    feedback_d[1].push(happy_d)
                                    feedback_d[2].push(neutral_d)
                                    feedback_d[3].push(unhappy_d)
                                })
                                console.log("cat list",category_list)
                                response.data.map( category => {
                                  var temp_userassist=[[],[]];
                                  Object.keys(category.user_assist_sub_catgeory).map(function(key) {
                                    temp_userassist[0].push(key);
                                    temp_userassist[1].push(category.user_assist_sub_catgeory[key]);
                                  });
                                  console.log("temp",temp_userassist)
                                  category_list.map(function(value, index, array) {
                                    if (temp_userassist[0].includes(value)) {
                                      all_userassist_categories[index].push(
                                         temp_userassist[1][temp_userassist[0].indexOf(value)]
                                      );
                                    } else {
                                      all_userassist_categories[index].push(0);
                                    }
                                  })
                                 });
                            feedback[0].push(happy_count,unhappy_count,neutral_count);
                           // transactions[1].push(transactions_count);
                           if(kernel == "All Categories"){
                            transactions[0].push("Total")
                            transactions[1].push(transactions_count);
                            users[0].push("Total")
                            users[1].push(user_count);
                            }
                            transactions_d[0].push("Total");
                            transactions_d[1].push(transactions_count)
                            //users[1].push(user_count);
                            users_d[0].push("Total");
                            users_d[1].push(user_count);
                            new_users_d[0].push("Total");
                            new_users_d[1].push(new_user_count);
                            feedback_d[0].push("Total")
                            feedback_d[1].push(happy_count)
                            feedback_d[2].push(neutral_count)
                            feedback_d[3].push(unhappy_count)

                            all_userassist_categories_d = all_userassist_categories_d.concat(
                            all_userassist_categories
                             );
                              user_assist_topics.unshift(["Category", "New Topics"]);
                              var status = 200

                            }
                         
                        } catch (error) {
                          console.log(error);
                          status = 404;
                        }

                     return{
                                transactions,users,feedback,all_userassist_categories_d,user_assist_topics,transactions_d,users_d,feedback_d,feedback_comments,new_users_d,status
                            }
                     
          })
          .catch(error => {
            let status = 404;
            return status;
          });
      } catch (error) {
        console.log(error);
        let status = 404;
        return status;
      }
    
  
}

function getChartComponents(
  transactions,
  users,
  feedback,
  changed
  
) {
  if (!changed) {
    
    if (true) {
        console.log(transactions,users,feedback)
      return (
        <Grid container spacing={3} className={styles.selectors}>
         <Grid item xs={12} sm={6}>
            <Paper className={styles.paper} elevation={10}>
              <div className="App">
                <Plot
                  data={[
                    {
                      x: transactions[0],
                      y: transactions[1],
                      type: "bar",
                      marker: { color: "#17BECF" }
                    }
                  ]}
                  layout={{ autosize: true, title: "Queries" }}
                  useResizeHandler={true}
                  style={{ width: "100%", height: "100%" }}
                  config={{ displaylogo: false }}
                />
              </div>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Paper className={styles.paper} elevation={10}>
              <div className="App">
                <Plot
                  data={[
                    {
                      x: users[0],
                      y: users[1],
                      type: "bar",
                      marker: { color: "#17BECF" }
                    }
                  ]}
                  layout={{ autosize: true, title: "Users" }}
                  useResizeHandler={true}
                  style={{ width: "100%", height: "100%" }}
                  config={{ displaylogo: false }}
                />
              </div>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Paper className={styles.paper} elevation={10}>
              <div className="App">
                <Plot
                  data={[
                    {
                      values: feedback[0],
                      labels: feedback[1],
                      type: "pie",
                      marker: { colors: ["#00b300","#ffff1a","#ff0000"] }
                    }
                  ]}
                  layout={{ autosize: true, title: "Feedback" }}
                  useResizeHandler={true}
                  style={{ width: "100%", height: "100%" }}
                  config={{ displaylogo: false }}
                />
              </div>
            </Paper>
          </Grid>
        </Grid>
      );
    } 
  } else {
    return <Grid />;
  }
}

class Dashboard extends Component {
  callGetData = () => {
    this.setState({ dataReceived: false, selected: false, error: false });

    let start_date =
      new Date(this.state.start_date).getFullYear() +
      "/" +
      (new Date(this.state.start_date).getMonth() + 1) +
      "/" +
      new Date(this.state.start_date).getDate();
    let end_date =
      new Date(this.state.end_date).getFullYear() +
      "/" +
      (new Date(this.state.end_date).getMonth() + 1) +
      "/" +
      new Date(this.state.end_date).getDate();

    getDataForGraph(
      start_date,
      end_date,
      this.state.kernel,
      this.state.allCategories
    ).then(res => {
        console.log(res)
      if (res.status == 200) {
        this.setState({
          // transactions,users,feedback,all_userassist_categories_d,user_assist_topics,transactions_d,users_d,feedback_d,feedback_comments
          transactions: res.transactions,
          users: res.users,
          feedback: res.feedback,
          all_userassist_categories_d:res.all_userassist_categories_d,
          user_assist_topics:res.user_assist_topics,
          queries_d:res.transactions_d,
          users_d:res.users_d,
          feedback_d:res.feedback_d,
          feedback_comments:res.feedback_comments,
          new_users_d:res.new_users_d,
          dataReceived:true
        });
      } else {
        this.setState({ error: true, dataReceived: true });
      }
    });
  };

  constructor(props) {
    super(props);
    this.inputLabel = createRef();
    let dateObj = new Date();
    var d = dateObj;
    var start_date = new Date(d.setDate(d.getDate() - 7));
    this.state = {
      dataReceived: false,
      kernel:"All Categories",
      allCategories:["All Categories","General","Security","Facility Management","Financial","Information Technology","Real Estate","Technical","Others"],
      start_date: [start_date],
      end_date: [new Date()],
      anchorEl: null,
      labelWidth: 0,
      feedback: [],
      labelWidth: 0,
      Time: [],
      users: [],
      feedback: [],
      feedback_d: [],
      transactions:[],
      queries_d: [],
      users_d: [],
      endDate: false,
      error: false,
      selected: false,
      transactions: [],
      all_userassist_categories_d: [],
      feedback_comments: [],
      user_assist_topics: [],
      new_users_d: [],
      start_date_d:
        new Date(start_date).getDate() +
        "/" +
        (new Date(start_date).getMonth() + 1) +
        "/" +
        new Date(start_date).getFullYear(),
      end_date_d:
        +new Date().getDate() +
        "/" +
        (new Date().getMonth() + 1) +
        "/" +
        new Date().getFullYear()
    };
    this.callGetData(this.state.date);
  }

  onStartDateChange = dateChange => {
    if (dateChange) {
      // console.log()
      let start_date_d =
        new Date(dateChange).getDate() +
        "/" +
        (new Date(dateChange).getMonth() + 1) +
        "/" +
        new Date(dateChange).getFullYear();

      this.setState(
        { start_date: dateChange, selected: true, start_date_d: start_date_d },
        () => {
          console.log("Dates on Change => ", dateChange);
          console.log("state => ", this.state.date);
        }
      );
    }
  };

  onEndDateChange = dateChange => {
    if (dateChange) {
      let end_date_d =
        new Date(dateChange).getDate() +
        "/" +
        (new Date(dateChange).getMonth() + 1) +
        "/" +
        new Date(dateChange).getFullYear();

      this.setState(
        { end_date: dateChange, selected: true, end_date_d: end_date_d },
        () => {
          console.log("Dates on Change => ", dateChange);
          console.log("state => ", this.state.date);
        }
      );
    }
  };

  handleBotChange = event => {
    this.setState({ bot_id: event.target.value, dataReceived: false }, () => {
      this.callGetData();
    });
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget }, () => {
      // console.log("setAnchorEl is", this.state.anchorEl)
    });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleChange = event => {
    this.setState({ age: event.target.value });
  };

  handleSystemChange = event => {
    this.setState({
      kernel: event.target.value,
      selected: true
    });
  };

  render() {
    const { classes } = this.props;
    const currentPath = this.props.location.pathname;
    // if(!this.props.gob_access){
    //     if(!this.props.admin_data){
    //         return(
    //             <Grid><div><h1>Loading.......</h1></div></Grid>
    //         )
    //     }
    //     return(
    //         <Grid><div><h1>You might not have required permissions to view the page.</h1></div></Grid>
    //     )
    // }
    return (
      <React.Fragment>
        <CssBaseline />
        <Topbar
         alerts_access = {this.props.alerts_access} 
          firstName={this.props.firstName}
          lastName={this.props.lastName}
          codeID={this.props.codeID}
          emailID={this.props.emailID}
          dashboard_access={this.props.dashboard_access}
          admin_access={this.props.admin_access}
          esb_access={this.props.esb_access}
          gob_access = {this.props.gob_access}
        />

        <div className={classes.root}>
          <Grid container spacing={2} justify={"center"}>
            <Grid item xs={12}>
              <Typography variant="h4" color="secondary">
                <span className={classes.title}>
                  Chatbot Performance Analytics for Global One Badge
                </span>
              </Typography>
            </Grid>

          {/*  <Grid item sm={3} className={classes.selectors}>
              <FormControl variant="standard" className={classes.formControl}>
                
                 <InputLabel shrink htmlFor="age-native-label-placeholder">
          Virtual Assistant
        </InputLabel>
                <NativeSelect >
                    <option >Global One Badge</option>
                  </NativeSelect>
               
                 <InputLabel
                  style={{ width: "200%", fontSize: "17px" }}
                  id="demo-simple-select-outlined-label"
                >
                  Virtual Assistant
                </InputLabel> <Select
                  disabled={!this.state.dataReceived}
                  style={{ fontSize: "17px" }}
                  ref={this.inputLabel}
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-filled-standard"
                  value={this.state.bot_id}
                  onChange={this.handleBotChange}
                  labelWidth={this.labelWidth}
                  inputProps={{
                    name: "bot_id",
                    id: "outlined-age-simple"
                  }}
                >
                  <MenuItem
                    value={parseInt(
                      process.env.REACT_APP_SMART_SERVICE_DESK_ID
                    )}
                  >
                    Global One Badge
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>*/}

            <Grid item sm={2} className={classes.selectors}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  autoOk
                  disabled={!this.state.dataReceived}
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  label="Start Date"
                  disableFuture
                  minDate={
                    new Date(parseInt("2020"), parseInt("02"), parseInt("03"))
                  }
                  maxDate={this.state.end_date}
                  value={this.state.start_date}
                  onChange={this.onStartDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item sm={2} className={classes.selectors}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disabled={!this.state.dataReceived}
                  autoOk
                  disableToolbar
                  disableFuture
                  margin="normal"
                  variant="inline"
                  id="date-picker-dialog"
                  label="End Date"
                  format="MM/dd/yyyy"
                  value={this.state.end_date}
                  minDate={this.state.start_date}
                  onChange={this.onEndDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>

            
              <Grid item sm={3} className={classes.selectors}>
                <FormControl variant="standard" className={classes.formControl}>
                  <InputLabel
                    style={{ width: "200%", fontSize: "17px" }}
                    id="demo-simple-select-outlined-label"
                  >
                    Category
                  </InputLabel>
                  <Select
                    disabled={!this.state.dataReceived}
                    style={{ fontSize: "17px" }}
                    ref={this.inputLabel}
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-filled-standard"
                    value={this.state.kernel}
                    onChange={this.handleSystemChange}
                  >
                    {this.state.allCategories.map(kernel => {
                      return <MenuItem value={kernel}>{kernel}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </Grid>
           
            {this.state.dataReceived ? (
              <Grid className={classes.apply}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={this.callGetData}
                >
                  Apply
                </Button>
              </Grid>
            ) : (
              <div />
            )}

            {this.state.dataReceived &&
            !this.state.selected && !this.state.error? (
              <Grid
                item
                sm={4}
                className={classes.downloadButton}
                style={{ paddingTop: "17px" }}
              >
                <Button variant="outlined">
                  <Download
                    bot={"Global One Badge"  }

            

                    queries={this.state.queries_d}
                    users={this.state.users_d}
                    feedback={this.state.feedback_d}
                    service={this.state.category}
                    all_userassist_categories_d={
                      this.state.all_userassist_categories_d
                    }
                    feedback_comments={this.state.feedback_comments}
                    user_assist_topics={this.state.user_assist_topics}
                    new_users={this.state.new_users_d}
                    startDate={this.state.start_date_d}
                    endDate={this.state.end_date_d}
                  />
                </Button>
              </Grid>
            ) : (
              <div />
            )}

            <Grid item xs={12}>
              <Typography variant="h5">
                {!this.state.selected && !this.state.error ? (
                  <div></div>
                ) : !this.state.error ? (
                  <Typography variant="body1" align="center">
                    Please click on apply to fetch data.
                  </Typography>
                ) : (
                  <div> </div>
                )}
              </Typography>
            </Grid>

            {this.state.error ? (
              <div>
                There was an error loading the data, Please try after some time.
              </div>
            ) : !this.state.dataReceived ? (
              <Grid item xs={1} sm={1}>
                <CircularProgress />
              </Grid>
            ) : (
              <Grid item xs={10}>
                {getChartComponents(
                  this.state.transactions,
                  this.state.users,
                  this.state.feedback,
                  this.state.selected
                )}
              </Grid>
            )}
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(withStyles(styles)(Dashboard));
