import React, { Component } from 'react'
import { Route, HashRouter, Switch } from 'react-router-dom'
import Dashboard from './components/Dashboard'
import Main from './components/Main'
import ScrollToTop from './components/ScrollTop'
import jwt from 'jwt-simple';
import ITbot from './components/ItAnalystBot'
import Admin from './components/admin.js'
import axios from 'axios'
import Gobdashboard from './components/Gobdashboard'
import GobBot from './components/gobBot';
import AlertDashboard from './components/AlertDashboard';


class PrivateRoute extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            isAuthenticated: false,
            admin: [],
            esb: [],
            superAdmin: [],
            data: false,
            error:false

        }

        this.parms = new URLSearchParams(document.location.search);
        this.saml_token = this.parms.get("saml")

        if(!this.saml_token && !localStorage.getItem("saml")){
            localStorage.setItem("pathname", this.props.location.pathname);
          }
          this.webpage_callback = process.env.REACT_APP_WEBPAGE_ROUTE +localStorage.getItem("pathname")
        if (this.saml_token) {
            localStorage.setItem("saml", this.saml_token)
        } else {
            this.stored_token = localStorage.getItem("saml")
            if (this.stored_token) {
                localStorage.clear()
                try{
                this.userProfile = jwt.decode(this.stored_token, process.env.REACT_APP_LOGIN_JWT_SECRET,false,process.env.REACT_APP_LOGIN_JWT_SECRET_ALGORITHM)
                }catch(error){
                    //console.log(error)
                    this.stored_token = false;
                }
                


            }
        }


    }

    componentDidMount() {

        if (this.stored_token) {
            this.getData()
        }

    }



    getData() {


        axios.get(process.env.REACT_APP_ACCESS_DATA_API,{auth: {
             username: process.env.REACT_APP_ACCESS_DATA_API_AUTH_USER,
             password: process.env.REACT_APP_ACCESS_DATA_API_AUTH_PASS
        }}).then(response => {
            this.setState({
                admin: response.data.dashboardUsers,
                esb: response.data.esbUsers,
                superAdmin: response.data.uamAdmin,
                goblist :response.data.GOBdashboardUsers,
                AnnouncementUsers:response.data.AnnouncementUsers,
                data: true
            });

        }).catch(error=> {
           // console.log(error)
             this.setState({
                data: true,
                error:true
            });
        });

    }


    render() {

        const { component: Component, ...rest } = this.props;
        let dashboard_access = false;
        let esb_access = false;
        let admin_access = false;
        let firstName, lastName, codeID, emailID, saml = "";
        let gob_access = false;
        let alerts_access = false;
        let admin_data = this.state.data;

        
        if (this.stored_token) {


            saml = this.stored_token
            let user_profile = this.userProfile

            firstName = user_profile.firstName
            lastName = user_profile.lastName
            codeID = user_profile.codeID
            emailID = user_profile.emailID
            let useremail = emailID.toLowerCase()

            if (this.state.data) {
                let dadmin = this.state.admin
                let esbusers = this.state.esb
                let uamadmin = this.state.superAdmin
                let gobusers = this.state.goblist
                let AnnouncementUsers = this.state.AnnouncementUsers

                try {
                    let dashAdmin = dadmin.includes(useremail)
                    let esbuser = esbusers.includes(useremail)
                    let suAdmin = uamadmin.includes(useremail)
                    gob_access = gobusers.includes(useremail)
                    alerts_access = AnnouncementUsers.includes(useremail)
                if (dashAdmin) {
                    dashboard_access = true
                }
                if (esbuser) {
                    esb_access = true
                }
                if (suAdmin) {
                    admin_access = true
                }
                } catch (error) {
                    // console.log(error) 
                }
                
            }



        }

        return (
            <Route
                {...rest}
                render={props =>
                    this.stored_token ? ( //this.stored_token
                        <Component {...props} saml={saml} firstName={firstName} lastName={lastName} codeID={codeID} emailID={emailID} dashboard_access={dashboard_access} admin_access={admin_access} esb_access={esb_access} gob_access={gob_access} alerts_access= {alerts_access} admin_data={admin_data}/>
                    ) : this.saml_token ? (
                        <Route
                            path="/"
                            component={() => {
                                window.location.href = this.webpage_callback.includes('null') ? process.env.REACT_APP_WEBPAGE_CALLBACK : this.webpage_callback ;
                                return null
                            } }
                            />
                    ) :(
                                <Route
                                    path="/"
                                    component={() => {
                                        window.location.href = process.env.REACT_APP_WEBPAGE_REDIRECT;
                                        return null
                                    } }
                                    />
                            )
                    // )
                }
                />
        )
    }
}



export default props => (
    <HashRouter>
        <ScrollToTop>
            <Switch>
                <PrivateRoute exact path='/' component={Main} />
                <PrivateRoute exact path='/dashboard' component={Dashboard} />
                  <PrivateRoute exact path='/gob' component={GobBot} />
                   <PrivateRoute exact path='/gobdashboard' component={Gobdashboard} />
                <PrivateRoute exact path='/ItAnalystBot' component={ITbot} />
                <PrivateRoute exact path='/admin' component={Admin} />
                <PrivateRoute exact path='/alerts' component={AlertDashboard} />
            </Switch>
        </ScrollToTop>
    </HashRouter>
)