import React, { Component } from "react";
import withStyles from '@material-ui/styles/withStyles';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle.min';


const styles = theme => ({
    announcement: {
        padding: theme.spacing(1, 2),
        fontSize: "12px",
        textAlign: "justify",
        fontFamily: "Lato,sans-serif",
        color: "#000000"
    },

    nested: {
        paddingLeft: theme.spacing(2),
        paddingBottom: theme.spacing(1)
    }
});

class Carousel extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }


    render() {
        const { classes } = this.props;
        return (
            <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel" style={{ paddingTop: "30px" }} data-interval="10000">

                <div className="carousel-inner">

                    <div className="carousel-item active">
                        <Grid xs={12} md={12} style={{}} >
                            <Typography variant="body1" align="left" className={classes.announcement}>
                                <b>FAQs</b>
                            </Typography >
                            <Typography variant="body1" align="left" className={classes.announcement}>
                                Date : 23rd January 2023
                            </Typography >
                            <Typography variant="body1" align="left" className={classes.announcement}>
                                Below services have been added to the User Assist/FAQ functionality.
                            </Typography>

                            <Typography className={classes.announcement}>
                                <ul>
                                    <li className={classes.nested} style={{ fontSize: "11.5px" }}>
                                        • CP M2O CHANNEL MANAGEMENT (B2B Sales Cloud (Salesforce cCRM))
                                    </li>

                                </ul>
                            </Typography>
                        </Grid>
                    </div>

                    <div className="carousel-item">
                        <Grid xs={12} md={12} style={{}} >
                            <Typography variant="body1" align="left" className={classes.announcement}>
                                <b>B2B Sales Cloud (Salesforce cCRM) Password Reset & Account Reactivation</b>
                            </Typography >
                            <Typography variant="body1" align="left" className={classes.announcement}>
                                Date : 14th November 2022
                            </Typography >
                            <Typography variant="body1" align="left" className={classes.announcement}>
                                Below new service has been added to the Chat bot for Password Reset and Account Reactivation functionality.
                            </Typography>

                            <Typography className={classes.announcement}>
                                <ul>
                                    <li className={classes.nested} style={{ fontSize: "11.5px" }}>
                                        • CP M2O CHANNEL MANAGEMENT (B2B Sales Cloud (Salesforce cCRM))
                                    </li>

                                </ul>
                            </Typography>
                        </Grid>
                    </div>

                    <div className="carousel-item">
                        <Grid xs={12} md={12} style={{}} >
                            <Typography variant="body1" align="left" className={classes.announcement}>
                                <b>FAQs</b>
                            </Typography >
                            <Typography variant="body1" align="left" className={classes.announcement}>
                                Date : 24th October 2021
                            </Typography >
                            <Typography variant="body1" align="left" className={classes.announcement}>
                                Below new service has been added to the Chat bot for User Assist/FAQ functionality.
                            </Typography>

                            <Typography className={classes.announcement}>
                                <ul>
                                    <li className={classes.nested} style={{ fontSize: "11.5px" }}>
                                        • Philips Store Subscription and Billing
                                    </li>

                                </ul>
                            </Typography>
                        </Grid>
                    </div>

                </div>

            </div>


        )
    }
}

export default withRouter(withStyles(styles)(Carousel));