import React from "react";
import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;



class Download extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
        //console.log(this.props)


    }
    render() {
        //console.log(this.props.service,this.props.service === "CF Information Management")
        if (this.props.bot.toUpperCase() === "ATHENA") {

            if (this.props.service.toUpperCase() === "FLS") {
                return (
                    <ExcelFile
                        element={<button>Download Analytics</button>}
                        filename={
                            this.props.bot + ' - ' + this.props.service +
                            " Data" +
                            " " +
                            this.props.startDate +
                            "-" +
                            this.props.endDate
                        }
                        >
                        <ExcelSheet
                            dataSet={[
                                {
                                    columns: ["Athena - First Line Support"],
                                    data: [" "]
                                },
                                {
                                    columns: this.props.flsService[0],
                                    data: this.props.flsService.slice(1)
                                }
                            ]}
                            name="Analytics"
                            />
                    </ExcelFile>
                );
            }

                if (this.props.service.toUpperCase() === "PIL CPLM" ) {
                
                return (

                    <ExcelFile element={<button>Download Analytics</button>} filename={this.props.bot  + ' - ' + this.props.service + ' Data' + " " + this.props.startDate + "-" + this.props.endDate}>

                        <ExcelSheet dataSet={[
                            {
                                columns: [this.props.bot + " - " + this.props.service],
                                data: [" "]
                            },
                            {
                                columns: ["User Transactions"],
                                data: [" "]

                            },
                            {
                                columns: this.props.queries_ud[0],
                                data: this.props.queries_ud.slice(1)
                            },
                            {
                                columns: [""],
                                data: [" "]
                            },
                            {
                                columns: ["User Assist Categories"],
                                data: [" "]
                            },
                            {
                                columns: this.props.user_assist_sub_category[0],
                                data: this.props.user_assist_sub_category.slice(1)
                            },
                            {
                                columns: [""],
                                data: [" "]
                            },
                            {
                                columns: ["User Assist New Topics"],
                                data: [" "]
                            },
                            {
                                columns: this.props.user_assist_messages[0],
                                data: this.props.user_assist_messages.slice(1)
                            },
                            {
                                columns: [""],
                                data: [" "]
                            },
                            {
                                columns: ["Windchill License Reactivation"],
                                data: [" "]
                            },
                            {
                                columns: this.props.windchill_transactions_d[0],
                                data: this.props.windchill_transactions_d.slice(1)
                            },
                            {
                                columns: [""],
                                data: [" "]
                            },
                            {
                            columns: ["Unsuccessful Windchill License Reactivation"],
                            data: [" "]
                            },
                            {
                            columns: this.props.reasons_windchill[0],
                            data: this.props.reasons_windchill.slice(1)
                             },
                            {
                                columns: [""],
                                data: [" "]
                            },
                            {
                                columns: ["Unique Users"],
                                data: ['']
                            },
                            {
                                columns: this.props.users[0],
                                data: this.props.users.slice(1)
                            },
                            {
                                columns: [""],
                                data: [" "]
                            },
                            {
                                columns: ["New Users"],
                                data: ['']
                            },
                            {
                                columns: this.props.new_users_d[0],
                                data: this.props.new_users_d.slice(1)
                            },
                            {
                                columns: [""],
                                data: [" "]
                            },
                            {
                                columns: ["Feedback"],
                                data: [" "]
                            },
                            {
                                columns: this.props.feedback[0],
                                data: this.props.feedback.slice(1)
                            },
                            {
                                columns: [""],
                                data: [" "]
                            },
                            {
                                columns: this.props.feedback_comment_system[0],
                                data: this.props.feedback_comment_system.slice(1)
                            }
                        ]} name="Analytics" />


                    </ExcelFile>

                );

            }

            if (this.props.service.toUpperCase() === "CP M2O CHANNEL MANAGEMENT" ) {
                
                return (

                    <ExcelFile element={<button>Download Analytics</button>} filename={this.props.bot + ' - ' + this.props.service + ' Data' + " " + this.props.startDate + "-" + this.props.endDate}>

                        <ExcelSheet dataSet={[
                            {
                                columns: [this.props.bot + " - " + this.props.service],
                                data: [" "]
                            },
                            {
                                columns: ["User Transactions"],
                                data: [" "]

                            },{
                                columns: this.props.queries[0],
                                data: this.props.queries.slice(1)

                            },
                            {
                                columns: [""],
                                data: [" "]
                            },
                            {
                                columns: ["Unsuccessful Password Reset Transactions"],
                                data: [" "]
                            },
                            {
                                columns: this.props.reasons_p[0],
                                data: this.props.reasons_p.slice(1)
                            },
                            {
                                columns: [""],
                                data: [" "]
                            },
                            {
                                columns: this.props.queries_ud[0],
                                data: this.props.queries_ud.slice(1)
                            },
                            {
                                columns: [""],
                                data: [" "]
                            },
                            {
                                columns: ["User Assist Categories"],
                                data: [" "]
                            },
                            {
                                columns: this.props.user_assist_sub_category[0],
                                data: this.props.user_assist_sub_category.slice(1)
                            },
                            {
                                columns: [""],
                                data: [" "]
                            },
                            {
                                columns: ["User Assist New Topics"],
                                data: [" "]
                            },
                            {
                                columns: this.props.user_assist_messages[0],
                                data: this.props.user_assist_messages.slice(1)
                            },
                            {
                                columns: [""],
                                data: [" "]
                            },
                            {
                                columns: ["cCRM Account Reactivation"],
                                data: [" "]
                            },
                            {
                                columns: this.props.m2o_ccrm_reactivate_transactions_d[0],
                                data: this.props.m2o_ccrm_reactivate_transactions_d.slice(1)
                            },
                            {
                                columns: [""],
                                data: [" "]
                            },
                            {
                            columns: ["Unsuccessful cCRM Account Reactivation"],
                            data: [" "]
                            },
                            {
                            columns: this.props.reasons_m2o_ccrm_reactivate[0],
                            data: this.props.reasons_m2o_ccrm_reactivate.slice(1)
                             },
                            {
                                columns: [""],
                                data: [" "]
                            },
                            {
                                columns: ["Unique Users"],
                                data: ['']
                            },
                            {
                                columns: this.props.users[0],
                                data: this.props.users.slice(1)
                            },
                            {
                                columns: [""],
                                data: [" "]
                            },
                            {
                                columns: ["New Users"],
                                data: ['']
                            },
                            {
                                columns: this.props.new_users_d[0],
                                data: this.props.new_users_d.slice(1)
                            },
                            {
                                columns: [""],
                                data: [" "]
                            },
                            {
                                columns: ["Feedback"],
                                data: [" "]
                            },
                            {
                                columns: this.props.feedback[0],
                                data: this.props.feedback.slice(1)
                            },
                            {
                                columns: [""],
                                data: [" "]
                            },
                            {
                                columns: this.props.feedback_comment_system[0],
                                data: this.props.feedback_comment_system.slice(1)
                            }
                        ]} name="Analytics" />


                    </ExcelFile>

                );

            }

             if (this.props.service.toUpperCase() === "PIL ONLINE MARKETING" ) {
                
                return (

                    <ExcelFile element={<button>Download Analytics</button>} filename={this.props.bot  + ' - ' + this.props.service + ' Data' + " " + this.props.startDate + "-" + this.props.endDate}>

                        <ExcelSheet dataSet={[
                            {
                                columns: [this.props.bot + " - " + this.props.service],
                                data: [" "]
                            },
                            {
                                columns: ["User Transactions"],
                                data: [" "]

                            },
                            {
                                columns: this.props.queries_ud[0],
                                data: this.props.queries_ud.slice(1)
                            },
                            {
                                columns: [""],
                                data: [" "]
                            },
                            {
                                columns: ["User Assist Categories"],
                                data: [" "]
                            },
                            {
                                columns: this.props.user_assist_sub_category[0],
                                data: this.props.user_assist_sub_category.slice(1)
                            },
                            {
                                columns: [""],
                                data: [" "]
                            },
                            {
                                columns: ["User Assist New Topics"],
                                data: [" "]
                            },
                            {
                                columns: this.props.user_assist_messages[0],
                                data: this.props.user_assist_messages.slice(1)
                            },
                            {
                                columns: [""],
                                data: [" "]
                            },
                            {
                                columns: ["Akamai Operations"],
                                data: [" "]
                            },
                            {
                                columns: this.props.akamai_transactions_d[0],
                                data: this.props.akamai_transactions_d.slice(1)
                            },
                            {
                                columns: [""],
                                data: [" "]
                            },
                            {
                            columns: ["Unsuccessful Akamai Operation"],
                            data: [" "]
                            },
                            {
                            columns: this.props.reasons_akamai[0],
                            data: this.props.reasons_akamai.slice(1)
                             },
                            {
                                columns: [""],
                                data: [" "]
                            },
                            {
                                columns: ["Unique Users"],
                                data: ['']
                            },
                            {
                                columns: this.props.users[0],
                                data: this.props.users.slice(1)
                            },
                            {
                                columns: [""],
                                data: [" "]
                            },
                            {
                                columns: ["New Users"],
                                data: ['']
                            },
                            {
                                columns: this.props.new_users_d[0],
                                data: this.props.new_users_d.slice(1)
                            },
                            {
                                columns: [""],
                                data: [" "]
                            },
                            {
                                columns: ["Feedback"],
                                data: [" "]
                            },
                            {
                                columns: this.props.feedback[0],
                                data: this.props.feedback.slice(1)
                            },
                            {
                                columns: [""],
                                data: [" "]
                            },
                            {
                                columns: this.props.feedback_comment_system[0],
                                data: this.props.feedback_comment_system.slice(1)
                            }
                        ]} name="Analytics" />


                    </ExcelFile>

                );

            }

            if (this.props.faqSystems.includes(this.props.service)&& this.props.service.toUpperCase() != "FLS") {

                return (

                    <ExcelFile element={<button>Download Analytics</button>} filename={this.props.bot  + ' - ' + this.props.service + ' Data' + " " + this.props.startDate + "-" + this.props.endDate}>

                        <ExcelSheet dataSet={[
                            {
                                columns: [this.props.bot + " - " + this.props.service],
                                data: [" "]
                            },
                            {
                                columns: ["User Transactions"],
                                data: [" "]

                            },
                            {
                                columns: this.props.queries_ud[0],
                                data: this.props.queries_ud.slice(1)
                            },
                            {
                                columns: [""],
                                data: [" "]
                            },
                            {
                                columns: ["User Assist Categories"],
                                data: [" "]
                            },
                            {
                                columns: this.props.user_assist_sub_category[0],
                                data: this.props.user_assist_sub_category.slice(1)
                            },
                            {
                                columns: [""],
                                data: [" "]
                            },
                            {
                                columns: ["User Assist New Topics"],
                                data: [" "]
                            },
                            {
                                columns: this.props.user_assist_messages[0],
                                data: this.props.user_assist_messages.slice(1)
                            },
                            {
                                columns: [""],
                                data: [" "]
                            },
                            {
                                columns: ["Unique Users"],
                                data: ['']
                            },
                            {
                                columns: this.props.users[0],
                                data: this.props.users.slice(1)
                            },
                            {
                                columns: [""],
                                data: [" "]
                            },
                            {
                                columns: ["New Users"],
                                data: ['']
                            },
                            {
                                columns: this.props.new_users_d[0],
                                data: this.props.new_users_d.slice(1)
                            },
                            {
                                columns: [""],
                                data: [" "]
                            },
                            {
                                columns: ["Feedback"],
                                data: [" "]
                            },
                            {
                                columns: this.props.feedback[0],
                                data: this.props.feedback.slice(1)
                            },
                            {
                                columns: [""],
                                data: [" "]
                            },
                            {
                                columns: this.props.feedback_comment_system[0],
                                data: this.props.feedback_comment_system.slice(1)
                            }
                        ]} name="Analytics" />


                    </ExcelFile>

                );

            }

            if (this.props.service.toUpperCase() === "PIL O2C ERP") {


                return (

                    <ExcelFile element={<button>Download Analytics</button>} filename={this.props.bot  + ' - ' + this.props.service + ' Data' + " " + this.props.startDate + "-" + this.props.endDate}>

                        <ExcelSheet dataSet={[
                            {
                                columns: [this.props.bot + " - " + this.props.service],
                                data: [" "]
                            },
                            {
                                columns: ["User Transactions"],
                                data: [" "]

                            }, {
                                columns: this.props.transactions[0],
                                data: this.props.transactions.slice(1)

                            },
                            {
                                columns: [""],
                                data: [" "]
                            },
                            {
                                columns: ["Unsuccessful Password Reset Transactions"],
                                data: [" "]
                            },
                            {
                                columns: this.props.reason_pwd_d[0],
                                data: this.props.reason_pwd_d.slice(1)
                            },
                            {
                                columns: [""],
                                data: [" "]
                            },
                            {
                                columns: ["Unsuccessful Account Unlock Transactions"],
                                data: [" "]
                            },

                            {
                                columns: this.props.reason_acc_d[0],
                                data: this.props.reason_acc_d.slice(1)
                            },
                            {
                                columns: [""],
                                data: [" "]
                            },
                            {
                                columns: this.props.queries_ud[0],
                                data: this.props.queries_ud.slice(1)
                            },
                            {
                                columns: [""],
                                data: [" "]
                            },
                            {
                                columns: ["User Assist Categories"],
                                data: [" "]
                            },
                            {
                                columns: this.props.all_userassist_categories_d[0],
                                data: this.props.all_userassist_categories_d.slice(1)
                            },
                            {
                                columns: [""],
                                data: [" "]
                            },
                            {
                                columns: ["User Assist New Topics"],
                                data: [" "]
                            },
                            {
                                columns: this.props.user_assist_topics[0],
                                data: this.props.user_assist_topics.slice(1)
                            },
                            {
                                columns: [""],
                                data: [" "]
                            },
                            {
                                columns: ["Total Users"],
                                data: ['']
                            },
                            {
                                columns: this.props.all_users[0],
                                data: this.props.all_users.slice(1)
                            },
                            {
                                columns: [""],
                                data: [" "]
                            },
                            {
                                columns: ["New Users"],
                                data: ['']
                            },
                            {
                                columns: this.props.all_new_users[0],
                                data: this.props.all_new_users.slice(1)
                            },
                            {
                                columns: [""],
                                data: [" "]
                            },
                            {
                                columns: ["Feedback"],
                                data: [" "]
                            },
                            {
                                columns: this.props.all_feedback_d[0],
                                data: this.props.all_feedback_d.slice(1)
                            },
                            {
                                columns: [""],
                                data: [" "]
                            },
                            {
                                columns: this.props.feedback_comment[0],
                                data: this.props.feedback_comment.slice(1)
                            }
                        ]} name="Analytics" />


                    </ExcelFile>

                );

            }
            
             if (this.props.service.toUpperCase() === "ALL SERVICES") {


                return (

                    <ExcelFile element={<button>Download Analytics</button>} filename={this.props.bot  + ' - ' + this.props.service + ' Data' + " " + this.props.startDate + "-" + this.props.endDate}>

                        <ExcelSheet dataSet={[
                            {
                                columns: [this.props.bot + " - " + this.props.service],
                                data: [" "]
                            },
                            {
                                columns: ["User Transactions"],
                                data: [" "]

                            }, {
                                columns: this.props.transactions[0],
                                data: this.props.transactions.slice(1)

                            },
                            {
                                columns: [""],
                                data: [" "]
                            },
                            {
                                columns: ["Unsuccessful Password Reset Transactions"],
                                data: [" "]
                            },
                            {
                                columns: this.props.reason_pwd_d[0],
                                data: this.props.reason_pwd_d.slice(1)
                            },
                            {
                                columns: [""],
                                data: [" "]
                            },
                            {
                                columns: ["Unsuccessful Account Unlock Transactions"],
                                data: [" "]
                            },

                            {
                                columns: this.props.reason_acc_d[0],
                                data: this.props.reason_acc_d.slice(1)
                            },
                            {
                                columns: [""],
                                data: [" "]
                            },
                            
                            {
                                columns: ["Akamai Operations"],
                                data: [" "]
                            },
                            {
                                columns: this.props.akamai_transactions_d[0],
                                data: this.props.akamai_transactions_d.slice(1)
                            },
                            {
                                columns: [""],
                                data: [" "]
                            },
                            {
                            columns: ["Unsuccessful Akamai Operation"],
                            data: [" "]
                            },
                            {
                            columns: this.props.reasons_akamai[0],
                            data: this.props.reasons_akamai.slice(1)
                             },
                            {
                                columns: [""],
                                data: [" "]
                            },
                            
                            {
                                columns: ["Windchill License Reactivation"],
                                data: [" "]
                            },
                            {
                                columns: this.props.windchill_transactions_d[0],
                                data: this.props.windchill_transactions_d.slice(1)
                            },
                            {
                                columns: [""],
                                data: [" "]
                            },
                            {
                            columns: ["Unsuccessful Windchill License Reactivation"],
                            data: [" "]
                            },
                            {
                            columns: this.props.reasons_windchill[0],
                            data: this.props.reasons_windchill.slice(1)
                             },
                            {
                                columns: [""],
                                data: [" "]
                            },
                            {
                                columns: ["cCRM Account Reactivation"],
                                data: [" "]
                            },
                            {
                                columns: this.props.m2o_ccrm_reactivate_transactions_d[0],
                                data: this.props.m2o_ccrm_reactivate_transactions_d.slice(1)
                            },
                            {
                                columns: [""],
                                data: [" "]
                            },
                            {
                            columns: ["Unsuccessful cCRM Account Reactivation"],
                            data: [" "]
                            },
                            {
                            columns: this.props.reasons_m2o_ccrm_reactivate[0],
                            data: this.props.reasons_m2o_ccrm_reactivate.slice(1)
                             },
                            {
                                columns: [""],
                                data: [" "]
                            },
                            {
                                columns: this.props.queries_ud[0],
                                data: this.props.queries_ud.slice(1)
                            },
                            {
                                columns: [""],
                                data: [" "]
                            },
                            {
                                columns: ["User Assist Categories"],
                                data: [" "]
                            },
                            {
                                columns: this.props.all_userassist_categories_d[0],
                                data: this.props.all_userassist_categories_d.slice(1)
                            },
                            {
                                columns: [""],
                                data: [" "]
                            },
                            {
                                columns: ["User Assist New Topics"],
                                data: [" "]
                            },
                            {
                                columns: this.props.user_assist_topics[0],
                                data: this.props.user_assist_topics.slice(1)
                            },
                            {
                                columns: [""],
                                data: [" "]
                            },
                            {
                                columns: ["Total Users"],
                                data: ['']
                            },
                            {
                                columns: this.props.all_users[0],
                                data: this.props.all_users.slice(1)
                            },
                            {
                                columns: [""],
                                data: [" "]
                            },
                            {
                                columns: ["New Users"],
                                data: ['']
                            },
                            {
                                columns: this.props.all_new_users[0],
                                data: this.props.all_new_users.slice(1)
                            },
                            {
                                columns: [""],
                                data: [" "]
                            },
                            {
                                columns: ["Feedback"],
                                data: [" "]
                            },
                            {
                                columns: this.props.all_feedback_d[0],
                                data: this.props.all_feedback_d.slice(1)
                            },
                            {
                                columns: [""],
                                data: [" "]
                            },
                            {
                                columns: this.props.feedback_comment[0],
                                data: this.props.feedback_comment.slice(1)
                            }
                        ]} name="Analytics" />


                    </ExcelFile>

                );

            }


            return (

                <ExcelFile element={<button>Download Analytics</button>} filename={this.props.bot   + ' - ' + this.props.service + ' Data' + " " + this.props.startDate + "-" + this.props.endDate}>

                    <ExcelSheet dataSet={[
                        {
                            columns: [this.props.bot + " - " + this.props.service],
                            data: [" "]
                        },

                        {
                            columns: this.props.queries[0],
                            data: this.props.queries.slice(1)
                        },
                        {
                            columns: [""],
                            data: [" "]
                        },
                        {
                            columns: this.props.queries_ud[0],
                            data: this.props.queries_ud.slice(1)
                        },
                        {
                            columns: [""],
                            data: [" "]
                        },
                        {
                            columns: ["Unsuccessful Password Reset Transactions"],
                            data: [" "]
                        },
                        {
                            columns: this.props.reasons_p[0],
                            data: this.props.reasons_p.slice(1)
                        },
                        {
                            columns: [""],
                            data: [" "]
                        },
                        {
                            columns: ["Unsuccessful Account Unlock Transactions"],
                            data: [" "]
                        },
                        {
                            columns: this.props.reasons_a[0],
                            data: this.props.reasons_a.slice(1)
                        },
                        {
                            columns: [""],
                            data: [" "]
                        },
                        {
                            columns: ["Total Users"],
                            data: ['']
                        },
                        {
                            columns: this.props.users[0],
                            data: this.props.users.slice(1)
                        },
                        {
                            columns: [""],
                            data: [" "]
                        },
                        {
                            columns: ["New Users"],
                            data: ['']
                        },
                        {
                            columns: this.props.new_users_d[0],
                            data: this.props.new_users_d.slice(1)
                        },
                        {
                            columns: [""],
                            data: [" "]
                        },
                        {
                            columns: ["Feedback"],
                            data: [" "]
                        },
                        {
                            columns: this.props.feedback[0],
                            data: this.props.feedback.slice(1)
                        },
                        {
                            columns: [""],
                            data: [" "]
                        },
                        {
                            columns: this.props.feedback_comment_system[0],
                            data: this.props.feedback_comment_system.slice(1)
                        }
                    ]} name="Analytics" />


                </ExcelFile>

            );
        }
        else {

            return (
                <ExcelFile element={<button>Download Analytics</button>} filename={this.props.bot + ' Data' + " " + this.props.startDate + "-" + this.props.endDate}>


                    <ExcelSheet dataSet={[{
                        columns: ["User Transactions"],
                        data: [" "]

                    },

                    {
                        columns: ["Transaction Type", "Transaction Count"],
                        data: [["ESB Service Check", this.props.esb[0]]]
                    },
                    {
                        columns: [""],
                        data: [" "]
                    },
                    {
                        columns: ["Unique Users"],
                        data: ['']
                    },
                    {
                        columns: ["Transaction Type", "Unique User Count"],
                        data: [["ESB Service Check", this.props.esb[1]]]
                    },
                    {
                        columns: [""],
                        data: [" "]
                    },
                    {
                        columns: ["Top Category"],
                        data: [" "]
                    },
                    {
                        columns: ["Feedback"],
                        data: [" "]
                    },
                    {
                        columns: this.props.feedback[0],
                        data: this.props.feedback.slice(1)
                    }
                    ]} name="Analytics" />

                </ExcelFile>);
        }




    }
}

export default Download;